<template>
  <div>
    <div class="title-row">
      <h2>Precautions Presets</h2>
    </div>
    <!-- /// Data Table /// -->
    <div class="table-wrapper">
        <v-data-table :headers="headers" :items="precautions" :items-per-page="10" class="elevation-1 fixed-action" item-key="key">

          <template v-slot:[`item.action`]="{ item }">
            <div class="d-flex align-center">
              <v-btn color="primary" @click="deleteItem(item.value)">
                <v-icon class="mdi mdi-delete"></v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
    </div>
  </div>
</template>

<script>

import { VDataTable } from "vuetify/labs/VDataTable";
import { db } from "../../db";
import { drugTypes, dosages, medroutes } from "../config";
import { post } from "@/apiClient";
export default {
  name: "SettingsTabPrecautionPresets",
  components: {
    VDataTable,
  },
  mounted() {
    this.loadPrecautionPresets();
    
  },
  methods: {
    getUrdu() {
      post("urdu", { english: this.selectedPrecaution.name }).then((res) => {
        this.selectedPrecaution.urdu = res.data;
      }).catch((err) => {
        console.log(err);
      })
    },
    loadPrecautionPresets() {
      console.log("loadPrecautionPresets");
      let linkkey = this.$route.params.linkkey;
      db.appobjects
        .where({ linkkey: linkkey, type: "precautiondefault" })
        .toArray()
        .then((precautions) => {
          // console.log("precautionsdefault", precautions);
          this.precautions = precautions.filter((item) => item.visible);
        });
    },
    async savePreset() {
      if (this.selectedPrecaution.key) {
        this.selectedPrecaution.modified = Date.now();
        this.selectedPrecaution.synced = 0;
        await db.appobjects.update(this.selectedPrecaution.key, JSON.parse(JSON.stringify(this.selectedPrecaution)));
        this.upsertDialog = false;
        this.loadPrecautions();
      }
      // this.loadDrugs();
      this.selectedPrecaution = {
        key: "",
        name: "",
        urdu: "",
      }
    },
    async toggleVisibility(item) {
      item.visible = !item.visible;
      item.synced = 0;
      item.modified = Date.now();
      item = JSON.parse(JSON.stringify(item));
      await db.appobjects.update(item.key, item);
       this.loadPrecautions();
    },
    deleteItem(item) {
      if (!confirm("Are you sure you want to delete this " + item.name + " preset?")) return;
      item.visible = false;
      item.synced = 0;
      item.modified = Date.now();
      db.appobjects.update(item.key, JSON.parse(JSON.stringify(item))).then(() => {
        this.loadPrecautionPresets();
      });

    },
  },
  data: () => ({
    precautions: [],
    upsertDialog: false,
    selectedPrecaution: null,
    headers: [
      {
        title: "Preset Name",
        key: "key",
        sortable: true,
        value: "name",
      },
      {
        title: "Action",
        key: "action",
        sortable: false,
        value: "action",
      }
    ]


  }),
  computed: {
    drugTypes() {
      return drugTypes;
    },
    dosages() {
      return dosages;
    },
    medroutes() {
      return medroutes;
    },
  },

}
</script>

<style scoped>

.fixed-action:deep(table th:last-of-type) {
  right: 0;
  position: sticky;
}
.fixed-action:deep(table td:last-of-type) {
  right: 0;
  position: sticky;
}

</style>