import Vue from "vue";
import Vuex from "vuex";
import { mTypes, gTypes, aTypes } from "./index";
import router from "../router";
import { db } from "../../db";
import { post, get } from "@/apiClient";
import { configFormTypes } from "@/config";
import { Auth } from "aws-amplify";
import axios from "axios";
let uuid = require("uuid");
let synctmr = null;
let imagessyncing = false;
export default new Vuex.Store({
  state: {
    uuid: "",
    patients: [],
    lastpatientsync: 0,
    lastactive: 0,
    language: {},
    db: db,
    socket: {
      isConnected: false,
      message: "",
      reconnectError: false,
    },
  },
  mutations: {
    [mTypes.SAVE_DATA](state, payload) {
      db.appobjects.put(JSON.parse(JSON.stringify(payload)));
    },
    [mTypes.ADD_PATIENT](state, payload) {
      db.appobjects.put(JSON.parse(JSON.stringify(payload)));
    },
    [mTypes.UPDATE_PATIENTS](state, payload) {
      state.patients = payload;
    },
    [mTypes.SET_LAST_ACTIVE](state, payload) {
      if (Date.now() - state.lastactive > 30000) {
        this.dispatch(aTypes.SYNC_DATABASE);
      }
      state.lastactive = payload;
    },
    [mTypes.ADD_CONFIG_DATA](state, payload) {
      db.appobjects.bulkPut(payload);
    },
    [mTypes[`ADD_${configFormTypes.medicine}`]](state, payload) {
      db.appobjects.bulkPut(payload);
    },
    [mTypes[`ADD_${configFormTypes.precaution}`]](state, payload) {
      db.appobjects.bulkPut(payload);
    },
    [mTypes[`ADD_${configFormTypes.diagnosis}`]](state, payload) {
      db.appobjects.bulkPut(payload);
    },
    [mTypes[`ADD_${configFormTypes.comorbidity}`]](state, payload) {
      db.appobjects.bulkPut(payload);
    },
    [mTypes[`REMOVE_${configFormTypes.medicine}`]](state, payload) {
      db.appobjects.delete(payload);
    },
    [mTypes[`REMOVE_${configFormTypes.precaution}`]](state, payload) {
      db.appobjects.delete(payload);
    },
    [mTypes[`REMOVE_${configFormTypes.diagnosis}`]](state, payload) {
      db.appobjects.delete(payload);
    },
    [mTypes[`REMOVE_${configFormTypes.comorbidity}`]](state, payload) {
      db.appobjects.delete(payload);
    },
  },
  actions: {
    async [aTypes.SYNC_IMAGES] () {
      // console.log('inside SYNC_IMAGES')
      if (imagessyncing) {
        return;
      }
      imagessyncing = true;
      await db.images.where("synced")
      .equals(0)
      .toArray()
      .then(async (data) => {
        for (let i = 0; i < data.length; i++) {
          await post(router.currentRoute.value.params.linkkey + "/visit/image/puturl", {key: data[i].key}).then(async (res) => {
            let options = {
              method: "PUT",
              headers: { "content-type": "binary/octet-stream" },
              data:   data[i].imagedata,
              url: res.data.url,
            };  
            if (data[i].key.endsWith(".v2")) {
              // data[i].imagedata is dataurl get mime and data in buffer
              let mime = data[i].imagedata.split(";")[0].split(":")[1];
              let dataurl = data[i].imagedata.split(",")[1];
              let buffer = base64ToArrayBuffer(dataurl);
              // options.headers["content-type"] = mime;
              options.data = buffer;
              // return;
            }
          
            await axios(options).then((ares) => {
              if (ares.status === 200 && ares.statusText === "OK") {
                if (data[i].key.endsWith(".v2")) {
                  db.images.update(data[i].key, { synced: 1, imagedata: "" });
                } else {
                  db.images.update(data[i].key, { synced: 1 });
                }
              }
            }).catch((err) => {
              console.log(err)
            })
          }).catch((err) => {
            console.log(err)
          })
        }
      }).catch((err) => {
        console.log(err)
      })
      imagessyncing = false;
    },
    async [aTypes.INIT_UUID](state) {
      let uuidstr = await db.config.get("uuid");
      if (!uuidstr) {
        uuidstr = { key: "uuid", value: uuid.v4() };
        await db.config.put(uuidstr);
      }
      state.state.uuid = uuidstr.value;
    },
    async [aTypes.LOAD_PATIENTS](state) {
      let lastsync = await db.config.get("lastsync_" + router.currentRoute.value.params.linkkey);
      if (!lastsync) {
        await db.config.put({ key: "lastpatientsync", value: 0 });
        lastsync = 0;
      } else {
        lastsync = lastsync.value || 0;
      }
      await get("patients?lastpatientsync=" + lastsync)
        .then(async (res) => {
          if (res.status === 200) {
            await db.config.update("lastpatientsync", {
              value: res.data.lastsync,
            });
            if (res.data.patients) {
              await db.appobjects.bulkPut(res.data.patients);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },
    async [aTypes.LOAD_DRUGS]() {
      get("data/drugs").then(async (res) => {
        localStorage.setItem("drugs", JSON.stringify(res.data));
        alert('Drugs loaded')
      })
      

      
    },
    async [aTypes.STOP_SYNC]() {
      if (synctmr) {
        clearTimeout(synctmr);
      }
    },
    async [aTypes.SYNC_DATABASE]() {
      if (synctmr) {
        clearTimeout(synctmr);
      }
      Auth.currentSession().then((session) => {
        localStorage.setItem("token", session.idToken.jwtToken);
      }).catch((err) => {
        console.log(err);
      })
      let lastsync = await db.config.get("lastsync_" + router.currentRoute.value.params.linkkey);
      db.appobjects
        .where("synced")
        .equals(0)
        .toArray()
        .then((data) => {
          data = data.map((item) => {
            item.muuid = this.state.uuid;
            return item;
          });
          let robj = {
            lastsync: lastsync ? lastsync.value : 0,
            inbound: data,
          };
          post( router.currentRoute.value.params.linkkey + "/data/sync", robj)
            .then((res) => {
              if (res.status === 200) {
                for (let i = 0; i < data.length; i++) {
                  if (res.data.updateret[i]) {
                    db.appobjects.update(data[i].key, { synced: 1 });
                  }
                }
              }
              let ndata = res.data.newdata.filter((item) => {
                return (
                  item.muuid !== this.state.uuid ||
                  Date.now() - item.modified > 1000 * 60 * 60
                );
              });
              db.appobjects.bulkPut(ndata);
              db.config
                .update("lastsync_" + router.currentRoute.value.params.linkkey, { value: res.data.lastsync })
                .then((update) => {
                  if (!update) {
                    db.config.put({
                      key: "lastsync_" + router.currentRoute.value.params.linkkey,
                      value: res.data.lastsync,
                    });
                  }
                });
            })
            .catch((err) => {});
        })
        .finally(() => {
          if (Date.now() - this.state.lastactive < 30000) {
            synctmr = setTimeout(() => {
              this.dispatch(aTypes.SYNC_DATABASE);
            }, 5000);
          }
        });
        this.dispatch(aTypes.SYNC_IMAGES);
    },
    [aTypes.SOCKET_MARKING_MESSAGE]() {},

    [aTypes.DISCONNECT_SOCKET](state) {
      if (state.state.socket.isConnected) {
        Vue.prototype.$disconnect();
      }
    },
    [aTypes.CONNECT_SOCKET](state, token) {
      if (!state.state.socket.isConnected) {
        Vue.prototype.$connect(
          process.env.VUE_APP_SOCKET_BASE_URL + "?token=" + token
        );
      }
    },
    authenticate(/*state, eve*/) {},
  },
  getters: {
    [gTypes.GET_PATIENTS](state) {
      return state.patients;
    },
  },
});

function base64ToArrayBuffer(base64) {
  var binaryString = atob(base64);
  var bytes = new Uint8Array(binaryString.length);
  for (var i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}