<template>
  <div class="content-wrapper">
    <div class="screen-title">
      <h2>Add New Patient</h2>
    </div>
    <div class="form-card">
      <v-form ref="addPatientForm">
        <v-row gutters="12" class="pt-4">
          <v-col cols="12" md="6" class="pt-0 pb-0">
            <label>Serial Number</label>
            <v-text-field disabled v-model="patient.key" color="primary" label="Serial Number"
              variant="outlined"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pt-0 pb-0">
            <label>Full Name *</label>
            <v-text-field v-model="patient.name" color="primary" label="Full Name" variant="outlined"
              :rules="nameRules"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pt-0 pb-0">
            <label>Gender *</label>
            <v-select v-model="patient.gender" color="primary" label="Gender" variant="outlined" :items="['Male', 'Female']">

            </v-select>
            <!-- <v-text-field v-model="patient.gender" color="primary" label="Gender" variant="outlined"
              :rules="genderRules"></v-text-field> -->
          </v-col>
          <v-col cols="12" md="6" class="pt-0 pb-0">
            <label>Date Of Birth *</label>
            <v-text-field v-model="patient.dateofbirth" type="date" color="primary" label="Date Of Birth"
              variant="outlined" :rules="DoBRules"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pt-0 pb-0">
            <label>Email Address</label>
            <v-text-field v-model="patient.email" color="primary" label="Email Address" variant="outlined"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pt-0 pb-0">
            <label>Address</label>
            <v-text-field v-model="patient.address" color="primary" label="Address" variant="outlined"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pt-0 pb-0">
            <label>Fathers Name / Husbands Name</label>
            <v-text-field v-model="patient.fatherhusbandname" color="primary" label="Fathers Name / Husbands Name"
              variant="outlined"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pt-0 pb-0">
            <label>Phone Number</label>
            <v-text-field v-model="patient.phonenumber" color="primary" label="Phone Number" variant="outlined"
              type="number"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pt-0 pb-0">
            <label>CNIC</label>
            <v-text-field v-model="patient.cnic" placeholder="xxxxx-xxxxxxx-x" color="primary" variant="outlined"
              @blur="validateCNIC" :rules="CNICRules"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pt-0 pb-0">
            <label>Next Appointment</label>
            <div class="d-flex mobile-view-column">
              <v-text-field v-model="patient.nextappointment" type="date" color="primary" label="Next Appointment"
                variant="outlined" ></v-text-field>
              <v-checkbox class="ml-3" v-model="patient.nextappointmentsurgical" label="Surgical Visit" color="primary"></v-checkbox>

              <v-btn class="mt-3 ml-3" color="primary" @click="patient.nextappointment = ''">Clear Appointment</v-btn>
            </div>
          </v-col>          
          <v-col cols="12" md="12" class="d-flex justify-end mb-3">
            <v-btn @click="savePatient" color="primary"> Save </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: "AddPatient",
  data: () => ({
    patient: {
      key: "",
      name: "",
      fatherhusbandname: "",
      email: "",
      gender: "",
      address: "",
      phonenumber: "",
      cnic: "",
      type: "patient",
      synced: 0,
      dateofbirth: "",
      clinicid: "",
      linkkey: "",
      parentid: "",
      nextappointment: "",
      nextappointmentsurgical: false,
    },
    nameRules: [(v) => !!v || "Name is required"],
    genderRules: [(v) => !!v || "Gender is required"],
    DoBRules: [(v) => !!v || "Date of Birth is required"],
    CNICRules: [],
    show: false,
  }),
  mounted() {
    if (this.$route.params.patientkey !== "new") {
      const patientkey = this.$route.params.patientkey;

      this.$store.state.db.appobjects.get(patientkey).then((res) => {
        this.patient = res;
      });
    } else {
      const linkkey = this.$route.params.linkkey;

      this.patient.key = Date.now().toString();
      this.patient.linkkey = linkkey;
      this.patient.clinicid = linkkey;
      this.patient.parentid = linkkey;
      let todaysdate = new Date();
      let todaydatestr = moment(todaysdate).format('YYYY-MM-DD');
      this.patient.nextappointment = todaydatestr;
    }
  },
  methods: {
    savePatient() {
      this.$refs.addPatientForm.validate().then(({ valid }) => {
        if (valid) {
          this.patient.modified = Date.now();
          this.patient.synced = 0;

          this.$store.commit("ADD_PATIENT", this.patient);
          this.$router.push({ name: "Dashboard" });
        }
      });
    },
    validateCNIC() {
      const CNICRegex = /^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$/;
      const CNCIWarningMsg = "Please enter a valid CNIC number";

      if (this.patient.cnic && !CNICRegex.test(this.patient.cnic)) {
        return this.CNICRules.push((v) => false || CNCIWarningMsg);
      }

      this.CNICRules.pop();
    },
  },
};
</script>

<style scoped>
.content-wrapper {
  padding: 84px 24px 24px;
}

.screen-title {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
}

.screen-title h2 {
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  letter-spacing: 0.5px;
}

.form-card {
  padding: 10px 20px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0 0 4px 0 #cecece;
}

.form-card label {
  display: block;
  font-size: 16px;
  margin: 0 0 6px 0;
}

.v-btn:deep(.v-icon) {
  color: #ffffff;
}

.v-btn:deep(.v-btn__content) {
  color: #ffffff;
}

.v-btn.bg-default:deep(.v-btn__content) {
  color: #000000;
}

.v-input:deep(.v-field:hover .v-field__outline__start) {
  border-color: #2f74bf;
}

.v-input:deep(.v-field:hover .v-field__outline__notch::before) {
  border-color: #2f74bf;
}

.v-input:deep(.v-field:hover .v-field__outline__notch::after) {
  border-color: #2f74bf;
}

.v-input:deep(.v-field:hover .v-field__outline__end) {
  border-color: #2f74bf;
}

@media (min-width: 350px) and (max-width: 650px) {
  .content-wrapper {
    padding: 80px 15px 15px;
  }
  .mobile-view-column {
    flex-direction: column;
  }
  .mobile-view-column .v-btn {
    margin-top: 0 !important;
    margin-left: 0 !important;
  }

}
</style>
