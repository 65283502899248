<template>
  <v-dialog class="medicine-modal" v-model="dialog" width="800" persistent>
    <v-card>
      <div class="card-header">
        Add {{ type }}
        <v-btn
          density="default"
          color="primary"
          size="small"
          icon="mdi-close"
          @click="close"
        ></v-btn>
      </div>
      <v-form ref="form" v-model="valid" lazy-validation>
        <!--TODO: Zubair make this label capitalize and design this modal as per theme-->
        <div class="card-content">
          <v-text-field
            v-model="names"
            variant="outlined"
            color="primary"
            :rules="namesRules"
            :label="type"
            required
            
          />
          <v-text-field
            v-model="urdu"
            variant="outlined"
            color="primary"
            label="Urdu"
            v-if="hasurdu"
          />          
          <v-autocomplete color="primary" v-if="icdenabled" chips closable-chips label="WHO/ICD" v-model:search="icdsearch" :items="whoicd" item-title="label"
            item-value="icdcode" :custom-filter="icdFilter" v-model="icdselected" multiple variant="outlined"></v-autocomplete>
          <div>{{ icdcombcode }}</div>
            <!-- <v-text-field
            v-model="generics"
            variant="outlined"
            color="primary"
            label="Generics"
            required
          /> -->
          <div class="d-flex justify-end btn-wrapper">
            <v-btn color="primary" @click="getUrdu" v-if="hasurdu">Get Urdu</v-btn>
            <v-btn color="primary" class="ml-4" @click="close"> Close </v-btn>
            <v-btn
              :disabled="!valid"
              color="primary"
              class="ml-4"
              @click="submit"
            >
              Save
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { configFormTypes, whoicd } from "@/config";
let debounce = require("lodash.debounce");
import { post } from "@/apiClient";
export default {
  name: "ConfigForm",
  props: {
    // TODO: Need to tackle from  props (Sync props with state)
    // isOpen: {
    //   type: Boolean,
    //   default: false
    // },
    closeHandler: {
      type: Function,
      default: function (entries) {},
    },
    type: {
      type: String,
      validator(value) {
        return configFormTypes[value];
      },
      default: "medicine",
    },
    separateBy: {
      type: String,
      validator(value) {
        return [",", "-", "_", "@", "$"].includes(value);
      },
      default: ",",
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    dialog: true,
    valid: true,
    names: "",
    generics: "",
    urdu: "",
    icdsearch: "",
    icdcombcode: "",
    icdselected: [],
    // icditems: [],
    namesRules: [(v) => !!v || "This field is required"],
    genericsRules: [(v) => !!v || "This field is required"],
  }),
  mounted() {
    console.log(whoicd.length);

  },
  computed: {
    whoicd() {
      if (!this.icdsearch && this.icdselected.length === 0) {
        return []
      }
      let leafs = whoicd
      let srch = this.icdsearch
      let searchterms = srch.split(" ")
      let found = []
      //filter so that all search terms are found

      for (let leaf of leafs) {
        let foundall = true
        for (let term of searchterms) {
            if (!leaf.label.toLowerCase().includes(term.toLowerCase())) {
                foundall = false
                break
            }
        }
        if (foundall) {
            found.push(leaf)
        }
      }
      let foundbycode = whoicd.find((f) => f.icdcode.toLowerCase() === srch.toLowerCase())
      if (foundbycode) {
        found.push(foundbycode)
      }      
      found = found.sort((a, b) => {
        if (a.label.toLowerCase() === srch.toLowerCase()) {
            return -1
        } else if (b.label.toLowerCase() === srch.toLowerCase()) {
            return 1
        } else if (a.label.toLowerCase().startsWith(srch.toLowerCase())) {
            return -1
        } else if (b.label.toLowerCase().startsWith(srch.toLowerCase())) {
            return 1
        } else if (a.label.toLowerCase().includes(srch.toLowerCase())) {
            return -1
        } else if (b.label.toLowerCase().includes(srch.toLowerCase())) {
            return 1
        } else {
            return 0
        }
    })

    found = found.slice(0, 50)
    // add items from icdselected that are not in found
    for (let item of this.icdselected) {
        if (!found.find((f) => f.icdcode === item)) {
            found.push(whoicd.find((f) => f.icdcode === item))
        }
    }
    // console.log(found.length)
      return found || []
    },
    hasurdu() {
      return this.type === "precaution" ;
    },
    icdenabled() {
      return this.type === "diagnosis" || this.type === "comorbidity" ;
    },
  },
  watch: {
    icdselected() {
      this.icdsearch = ""
      this.icdcombcode = this.icdselected.map((item) => item).join("-")
      this.names = ""
      for (let item of this.icdselected) {
        let icditem = whoicd.find((f) => f.icdcode === item)
        if (icditem) {
          this.names = this.names + " " + icditem.label
        }
      }
      // this.names = this.icdselected.map((item) => item.label).join(", ")
    },
  },
  methods: {
    icdFilter(item, queryText, itemText) {
      return true;
    },
    getUrdu() {
      post("urdu", { english: this.names }).then((res) => {
        this.urdu = res.data;
      }).catch((err) => {
        console.log(err);
      })
    },
    submit() {
      const names = [this.names] //.split(this.separateBy);
      const generics = [this.generics] //.split(this.separateBy);
      const { linkkey } = this.$route.params;
      //TODO: Salik if length not match show error message.
      const timeStamp = Date.now();
      const entries = names.map((name, i) => {
        return {
          key: `${timeStamp}-${i}`,
          linkkey,
          parentid: linkkey,
          type: this.type,
          detail: "",
          visible: true,
          icdcodes: this.icdselected.join("-"),
          synced: 0,
          urdu: this.urdu,
          modified: timeStamp,
          name,
          generics: generics[i],
        };
      });
      /*
      TODO: Asim do we need inset record in DB via store? this doesn't do anything also hard to capture error and success,
            Salik Need to add loading and success and error message support and then reset form only in success case.
      */
      this.$store.commit(`ADD_CONFIG_DATA`, entries);
      this.$store.commit(`ADD_${this.type}`, entries);
      this.$refs.form.reset();
      // TODO: Salik: Need a better solution
      this.closeHandler(entries);
    },
    close() {
      this.dialog = false;
      this.closeHandler();
    },
  },
};
</script>
<style scoped>
.medicine-modal .card-header {
  display: flex;
  font-size: 20px;
  color: #ffffff;
  font-weight: 500;
  padding: 10px 24px;
  align-items: center;
  background: #2f74bf;
  justify-content: space-between;
}

.medicine-modal .card-header .v-btn {
  box-shadow: 0 0 0 0 #cecece;
}

.medicine-modal .card-header .v-btn:deep(.v-icon) {
  font-size: 24px;
  color: #ffffff;
}

.medicine-modal .card-content {
  padding: 24px;
}

.medicine-modal .card-content:deep(.v-label) {
  text-transform: capitalize;
}

.medicine-modal
  .card-content
  .v-input:deep(.v-field:hover .v-field__outline .v-field__outline__start) {
  border-color: #2f74bf;
}

.medicine-modal
  .card-content
  .v-input:deep(
    .v-field:hover .v-field__outline .v-field__outline__notch::before
  ) {
  border-color: #2f74bf;
}

.medicine-modal
  .card-content
  .v-input:deep(
    .v-field:hover .v-field__outline .v-field__outline__notch::after
  ) {
  border-color: #2f74bf;
}

.medicine-modal
  .card-content
  .v-input:deep(.v-field:hover .v-field__outline .v-field__outline__end) {
  border-color: #2f74bf;
}

.btn-wrapper .v-btn {
  min-height: 40px;
  padding: 2px 20px 0 20px;
}

.btn-wrapper .v-btn:disabled {
  opacity: 0.5;
}

.btn-wrapper .v-btn:deep(.v-btn__content) {
  color: #ffffff;
}
@media (min-width: 350px) and (max-width: 599px) {
  .v-overlay-container .medicine-modal {
    padding-right: 0 !important;
  }
  .medicine-modal:deep(.v-overlay__content) {
    max-width: calc(100% - 20px);
  }
  .medicine-modal .btn-wrapper .v-btn {
    padding: 2px 14px 0 14px;
  }
}
</style>
