<template>
  <div>
    <div class="title-row mb-4 d-flex align-center justify-space-between">
      <h2>Med Frequencies</h2>
      <v-btn color="primary" @click="upsertItem(null)">
        <v-icon class="mdi mdi-plus mr-1"></v-icon>
        Add Med Frequency
      </v-btn>
    </div>
    <!-- /// Data Table /// -->
    <div class="table-wrapper">
      <v-data-table :headers="headers" :items="objs" :items-per-page="10" class="elevation-1 fixed-action" item-key="key">
        <template v-slot:[`item.urdu`]="{ item }">
          {{ item.value.urdu }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <div class="d-flex align-center">
            <v-btn v-if="item.value.visible" class="mr-3" color="primary" @click="toggleVisibility(item.value)">
              <v-icon class="mdi mdi-eye"></v-icon>
            </v-btn>
            <v-btn v-else color="primary" class="mr-3" @click="toggleVisibility(item.value)">
              <v-icon class="mdi mdi-eye-off"></v-icon>
            </v-btn>
            <v-btn color="primary" @click="upsertItem(item.value)">
              <v-icon class="mdi mdi-pencil"></v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="upsertDialog" class="medicine-modal" width="600">
      <v-card>
        <v-card-title>
          <span class="headline">{{ this.selectedItem.name ? "Edit" : "Add" }} Med Frequency Preset</span>
          <v-btn
              density="default"
              color="primary"
              size="small"
              icon="mdi-close"
              @click="upsertDialog = false"
          ></v-btn>
        </v-card-title>
        <v-card-text class="pt-9 pb-5">
          <v-row>
            <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
              <v-text-field variant="outlined" color="primary" v-model="selectedItem.name" label="Name"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
              <v-text-field variant="outlined" color="primary" v-model="selectedItem.urdu" label="Urdu"></v-text-field>
            </v-col>
          </v-row>          
          <div class="d-flex align-center justify-end mt-3">
            <v-btn color="primary" text @click="getUrdu">Get Urdu</v-btn>
            <v-btn color="primary ml-3" text @click="upsertDialog = false">Cancel</v-btn>
            <v-btn color="primary" class="ml-3" text @click="saveItem">Save</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { VDataTable } from "vuetify/labs/VDataTable";
import { db } from "../../db";
import { drugTypes, dosages, medroutes } from "../config";
import { post } from "@/apiClient";
export default {
  name: "SettingsTabMedFrequencies",
  components: {
    VDataTable,
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    getUrdu() {
      post("urdu", { english: this.selectedItem.name }).then((res) => {
        this.selectedItem.urdu = res.data;
      }).catch((err) => {
        console.log(err);
      })
    },    
    loadItems() {
      let linkkey = this.$route.params.linkkey;
      db.appobjects
        .where({ linkkey: linkkey, type: "medfrequency" })
        .toArray()
        .then((objs) => {
          console.log(objs);
          this.objs = objs;
        });
    },
    async saveItem() {
      // console.log(this.selectedItem);
      if (!this.selectedItem.key) {
        this.selectedItem.key = "medfrequency_" + Date.now();
        this.selectedItem.linkkey = this.$route.params.linkkey;
        this.selectedItem.type = "medfrequency";
        this.selectedItem.parentid = this.$route.params.linkkey;
        this.selectedItem.visible = true;        
      } else {
        // await db.appobjects.update(this.selectedItem.key, JSON.parse(JSON.stringify(this.selectedItem)));
        // this.upsertDialog = false;
      }
        this.selectedItem.modified = Date.now();
        this.selectedItem.synced = 0;
        await db.appobjects.put(JSON.parse(JSON.stringify(this.selectedItem)));
        this.upsertDialog = false;
        this.loadItems();
      // this.loadDrugs();
      this.selectedItem = {
        key: "",
        name: "",
        urdu: "",
      }
    },
    async toggleVisibility(item) {
      item.visible = !item.visible;
      item.synced = 0;
      item.modified = Date.now();
      item = JSON.parse(JSON.stringify(item));
      await db.appobjects.update(item.key, item);
       this.loadItems();
    },
    upsertItem(item = null) {
      if (item) {
        this.selectedItem = JSON.parse(JSON.stringify(item));
        this.upsertDialog = true;
      } else {
        this.selectedItem = {
          key: "",
          name: "",
          urdu: "",
        };
        this.upsertDialog = true;
      }
    },
  },
  data: () => ({
    objs: [],
    upsertDialog: false,
    selectedItem: null,
    headers: [
      {
        title: "Frequency",
        key: "key",
        sortable: true,
        value: "name",
      },
      {
        title: "Urdu",
        key: "urdu",
        sortable: true,
        value: "urdu",
      },
      {
        title: "Action",
        key: "action",
        sortable: false,
        value: "action",
      }
    ]


  }),
  computed: {
    drugTypes() {
      return drugTypes;
    },
    dosages() {
      return dosages;
    },
    medroutes() {
      return medroutes;
    },
  },

}
</script>

<style scoped>

.fixed-action:deep(table th:last-of-type) {
  right: 0;
  position: sticky;
}
.fixed-action:deep(table td:last-of-type) {
  right: 0;
  position: sticky;
}

</style>