<template>
  <div class="content-wrapper">
    <div class="screen-title">
      <h2>Settings</h2>
    </div>
    <v-card>
      <v-tabs class="tabs-wrapper" v-model="tab" bg-color="primary">
        <v-tab value="drprofile">Dr Profile</v-tab>
        <v-tab value="one">Medicines</v-tab>
        <v-tab value="two">Med Type Presets</v-tab>
        <v-tab value="two2">Med Freqs</v-tab>
        <v-tab value="three">Comorbidities</v-tab>
        <v-tab value="four">Diagnoses</v-tab>
        <v-tab value="prescriptionpresets">Presc Presets</v-tab>
        <v-tab value="five">Precautions</v-tab>
        <v-tab value="six">Precaution Presets</v-tab>
      </v-tabs>

      <v-card-text class="pa-5 mobile-view-pa">
        <v-window v-model="tab">
          <!-- Medicines -->
          <v-window-item value="drprofile">
            <SettingsTabDrProfile />
          </v-window-item>          
          <v-window-item value="one">
            <SettingsTabMedicines />
          </v-window-item>

          <!-- Precautions -->
          <v-window-item value="two">
            <SettingsTabMedTypePresets />
          </v-window-item>
          <v-window-item value="two2">
            <SettingsTabMedFrequencies />
          </v-window-item>
          

          <!-- Comorbidities -->
          <v-window-item value="three">
            <SettingsTabComorbidities />
          </v-window-item>
          <v-window-item value="prescriptionpresets">
            <SettingsTabPrescriptionPresets />
          </v-window-item>
          <!-- Diagnosis -->
          <v-window-item value="four">
            <SettingsTabDiagnoses />
          </v-window-item>
          <v-window-item value="five">
            <SettingsTabPrecautions />
          </v-window-item>
          <v-window-item value="six">
            <SettingsTabPrecautionPresets />
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>
    <ConfigForm v-if="configDataType" :type="configDataType" :closeHandler="() => onConfigDialogClose()" />
    <!-- /// Medicine Delete Modal /// -->
    <v-dialog class="delete-modal" v-model="dialog" width="600">
      <v-card>
        <v-card-text>
          <p>Are you sure you want to delete this medicine.</p>
          <div class="d-flex justify-center">
            <v-btn class="danger-btn" @click="dialog = false"> No </v-btn>
            <v-btn color="primary" @click="deleteSelectedChip()"> Yes </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- /// Medicine Delete Modal /// -->
  </div>
</template>

<script>
import { ConfigForm, 
  SettingsTabMedicines, 
  SettingsTabMedTypePresets, 
  SettingsTabComorbidities, 
  SettingsTabDiagnoses,
  SettingsTabPrecautions, 
  SettingsTabMedFrequencies, 
  SettingsTabDrProfile,
  SettingsTabPrescriptionPresets,
  SettingsTabPrecautionPresets

} from "@/components";
// import { configFormTypes } from "@/config";
// import { db } from "../../db";

export default {
  name: "SettingScreen",
  components: {
    ConfigForm,
    SettingsTabMedicines,
    SettingsTabMedTypePresets,
    SettingsTabComorbidities,
    SettingsTabDiagnoses,
    SettingsTabPrecautions,
    SettingsTabMedFrequencies,
    SettingsTabDrProfile,
    SettingsTabPrescriptionPresets,
    SettingsTabPrecautionPresets
  },
  data: () => ({
    tab: null,
    configDataType: null,
    medicines: [],
    precautions: [],
    comorbidities: [],
    diagnosis: [],
    dialog: false,
    selectedChip: null,
  }),
  methods: {
    loadDrugs() {
      this.$store.dispatch("LOAD_DRUGS");
    },
  },
};
</script>

<style scoped>
.content-wrapper {
  padding: 84px 24px 24px;
}

.screen-title {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
}

.screen-title h2 {
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  letter-spacing: 0.5px;
}

.v-btn:deep(.v-icon) {
  color: #ffffff;
}

.v-btn:deep(.v-btn__content) {
  color: #ffffff;
}

.v-btn.bg-default:deep(.v-btn__content) {
  color: #000000;
}

/* //// Medicine Tabs Styling //// */
.title-row {
  display: flex;
  margin-bottom: 25px;
  align-items: center;
  justify-content: space-between;
}

.chip-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.chip-wrapper .v-chip {
  height: auto;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  align-items: center;
  background: #eeffff;
  margin: 0 12px 12px 0;
  padding: 6px 15px 5px 15px;
}

.chip-wrapper .v-chip .v-icon {
  cursor: pointer;
  font-size: 28px;
  margin-left: 3px;
}

.modal-title {
  display: flex;
  padding: 15px 20px;
  align-items: center;
  background: #2f74bf;
  justify-content: space-between;
}

.modal-title h2 {
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
}

.modal-title .v-btn {
  box-shadow: 0 0 0 0 #cecece;
}

/* //// Medicine Tabs Styling //// */

.delete-modal .v-card .v-card-text {
  padding: 30px 30px 30px !important;
}

.delete-modal .v-card .v-card-text p {
  font-size: 20px;
  color: #000000;
  font-weight: 400;
  text-align: center;
  margin: 0 0 30px 0;
}

.delete-modal .v-btn {
  min-width: 70px;
  min-height: 40px;
  margin: 0 10px 0 10px;
  padding: 2px 20px 0 20px;
}

.delete-modal .danger-btn {
  color: #ffffff;
  background: #e74d3e;
}

/* /// Data Table Styling /// */
.data-table:deep(table th) {
  font-size: 16px;
  height: auto !important;
  color: #333333 !important;
  font-weight: 500 !important;
  background: #eef7f7 !important;
  padding: 13px 16px 12px 16px !important;
}

.data-table:deep(table td) {
  font-size: 16px;
  color: #333333;
  font-weight: 400;
  height: auto !important;
  padding: 11px 16px 10px 16px !important;
}

.data-table:deep(table td .v-btn) {
  margin-right: 10px;
}

.data-table:deep(table td .v-btn .v-icon) {
  font-size: 18px;
}

/* /// Data Table Styling /// */

@media (min-width: 350px) and (max-width: 650px) {
  .content-wrapper {
    padding: 80px 15px 15px;
  }

  .table-wrapper .v-table:deep(.v-data-table-footer) {
    padding-left: 0;
    padding-right: 0;
    padding-top: 15px;
    padding-bottom: 12px;
  }

  .table-wrapper .v-table:deep(.v-data-table-footer .v-data-table-footer__items-per-page) {
    width: 100%;
    padding: 0 15px 5px 15px;
  }

  .table-wrapper .v-table:deep(.v-data-table-footer .v-data-table-footer__items-per-page span) {
    flex: 1;
  }

  .table-wrapper .v-table:deep(.v-data-table-footer .v-data-table-footer__items-per-page .v-input) {
    max-width: 100px;
  }

  .table-wrapper .v-table:deep(.v-data-table-footer .v-data-table-footer__info) {
    padding: 0 6px;
  }

  .tabs-wrapper {
    height: auto;
  }

  .tabs-wrapper .v-tab {
    height: auto;
    padding: 11px 10px 12px 10px;
  }

  .tabs-wrapper .v-tab--selected .v-tab__slider {
    background: #00abab;
  }

  .mobile-view-pa {
    padding: 12px !important;
  }

  .v-window .title-row h2 {
    font-size: 16px;
  }

  .v-window .title-row .v-btn {
    font-size: 12px;
    padding: 2px 10px 0 10px;
  }

  .table-wrapper .v-table {
    white-space: nowrap;
  }

}
</style>
