<template>
    <div class="login-container">
        <div class="card">
            <img class="logo" src="./images/edu-logo.png" alt="" />
            <p>
                Enter the email address associated with your account and we'll send you a link to reset your password.
            </p>
            <v-form v-model="valid">
                <v-text-field color="primary" type="email" label="Email Address" outlined></v-text-field>
                <v-btn size="large" color="primary" class="w-100">
                    Continue
                </v-btn>
                <div class="d-flex justify-end mt-5">
                    <router-link to="#" class="primary-color" @click="login()">Back to Login</router-link>
                </div>
            </v-form>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "ForgotPassword",
    data: () => ({
        checkbox: false,
        valid: false,
    }),
    methods: {
        login: function () {
            this.$router.push("/");
        },
    },
};
</script>
  
<style scoped>
.login-container {
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
}

.login-container .card {
    width: 500px;
    border-radius: 4px;
    background: #ffffff;
    padding: 15px 15px 25px;
    box-shadow: 0 4px 12px 0 #cecece;
}

.login-container .card .logo {
    width: 300px;
    height: auto;
    display: block;
    margin: 20px auto 50px;
}

.v-input:deep(.v-field .v-field__overlay) {
    background-color: inherit;
}

.login-container .card:deep(.v-label) {
    margin: 0;
    line-height: 16px;
}

.login-container .card:deep(input) {
    padding-left: 0;
    padding-right: 0;
}

.ml-minus-10 {
    margin-left: -10px;
}

.primary-color {
    color: #2f74bf;
}

p {
    font-size: 14px;
    font-weight: 300;
    color: #666666;
    margin-bottom: 20px;
}

.v-btn:deep(.v-btn__content) {
    color: #ffffff;
}

@media (min-width: 350px) and (max-width: 550px) {
    .login-container .card {
        width: 90%;
    }

    .login-container .card .logo {
        width: 250px;
        margin: 20px auto 30px;
    }
}
</style>
  