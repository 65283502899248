<template>
  <div class="content-wrapper">
    <div class="screen-title">
      <h2>Patient visits for: {{ patient ? patient.name : "" }}</h2>
      <v-col cols="auto" class="pa-0">
        <v-btn color="primary" @click="addNewVisitPatient">
          <v-icon class="mdi mdi-plus mr-1"></v-icon>
          New Visit
        </v-btn>
      </v-col>
    </div>
    <div class="table-card">
      <!-- TODO: Hide search functionality for now -->
      <!-- <div class="filter-search">
        <v-select
          color="primary"
          v-model="filterType"
          class="filter-box"
          label="Patients Filter"
          :items="filteredOpts"
          variant="outlined"
        ></v-select>
        <v-text-field
          color="primary"
          class="search-box"
          label="Start typing here to search"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
        ></v-text-field>
      </div> -->
      <v-data-table :headers="tableheaders" :items="visits" :items-per-page="10" class="elevation-1 fixed-action" item-key="key">
        <template v-slot:[`item.visitdate`]="{ item }">
          {{ formatVisitDate(item.value.visitdate) }}
        </template>
        <template v-slot:[`item.visittype`]="{ item }">
          <span>
            {{ item.value.visittype || "Clinical Visit" }}
          </span>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-btn color="primary" @click="$router.push('visit/' + item.value.key)">
            <v-icon class="mdi mdi-pencil"></v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { VDataTable } from "vuetify/labs/VDataTable";
import { liveQuery } from "dexie";
import { useObservable } from "@vueuse/rxjs";
import moment from "moment";
import { db } from "../../db";
export default {
  components: {
    VDataTable,
  },
  setup() {
    const route = useRoute();
    return {
      db,
      visits: useObservable(
        liveQuery(() =>
          db.appobjects
            .where({
              parentid: route.params.patientkey,
              type: "patientvisit",
            })
            .desc()
            .toArray()
        )
      ),
      patient: useObservable(
        liveQuery(() =>
          db.appobjects
            .where({
              // type: "patient",
              key: route.params.patientkey,
            })
            .first()
        )
      ),
    };
  },

  name: "PatientVisits",
  data: () => ({
    show: false,
    tableheaders: [
      {
        title: "Visit Date",
        key: "visitdate",
      },
      {
        title: "Visit Type",
        key: "visittype",
      },
      {
        title: "Action",
        key: "action",
      },
    ],
  }),
  mounted() { 

  },
  methods: {
    toggleShow(trip) {
      trip.show = !trip.show;
    },
    async addNewVisitPatient() {
      const { params } = this.$route;
      const payload = {
        key: params.patientkey + "." + Date.now().toString(),
        linkkey: params.linkkey,
        parentid: params.patientkey,
        type: "patientvisit",
        visitdate: Date.now(),
        modified: Date.now(),
        synced: 0,
      };
      // console.log(this.visits);
      let bputobjs = [];
      bputobjs.push(payload);
      if (this.visits.length > 0) {
        // payload.modified = this.visits[0].value.visitdate;
        let visitdetails = await db.appobjects.where({
          parentid: this.visits[0].key,
        }).toArray();
        // console.log(visitdetails);
        let comorbidities = visitdetails.find((x) => x.type === "visit_comorbidities");
        if (comorbidities) {
          comorbidities.key = payload.key + ".visit_comorbidities";
          comorbidities.parentid = payload.key;
          comorbidities.modified = Date.now();
          comorbidities.synced = 0;
          bputobjs.push(comorbidities);
        }
        let diagnosis = visitdetails.find((x) => x.type === "visit_diagnosis");
        if (diagnosis) {
          diagnosis.key = payload.key + ".visit_diagnosis";
          diagnosis.parentid = payload.key;
          diagnosis.modified = Date.now();
          diagnosis.synced = 0;
          bputobjs.push(diagnosis);
        }
        let pasthistory = visitdetails.find((x) => x.type === "visit_pasthistory");
        if (pasthistory) {
          pasthistory.key = payload.key + ".visit_pasthistory";
          pasthistory.parentid = payload.key;
          pasthistory.modified = Date.now();
          pasthistory.synced = 0;
          bputobjs.push(pasthistory);
        }

      }
      // console.log(bputobjs);
      await db.appobjects.bulkPut(bputobjs);
      this.$router.push(`visit/${payload.key}`);
      // let lastVisit = this.visits[0];

      
      // this.$store.commit("SAVE_DATA", payload);
      // this.$router.push(`visit/${payload.key}/new`);
    },
    formatVisitDate(date) {
      return moment(date).format("Do MMMM YYYY, h:mm a");
    },
  },
};
</script>

<style scoped>
.content-wrapper {
  padding: 84px 24px 24px;
}

.screen-title {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
}

.screen-title h2 {
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  letter-spacing: 0.5px;
}

.table-card {
  padding: 10px 20px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0 0 4px 0 #cecece;
}

.filter-search {
  display: flex;
  margin-top: 10px;
  align-items: center;
}

.filter-search .filter-box {
  max-width: 220px;
}

.filter-search .filter-box:deep(.v-label) {
  line-height: 16px;
}

.filter-search .filter-box:deep(.v-field__input) {
  min-height: 45px;
  padding: 10px 0 10px 15px;
}

.filter-search .filter-box:deep(.v-field__append-inner) {
  padding-top: 12px;
}

.filter-search .filter-box:deep(.v-field__outline__end) {
  border-radius: 0;
}

.filter-search .search-box {
  flex: 1;
}

.filter-search .search-box:deep(.v-field__input) {
  min-height: 45px;
  padding: 10px 10px 10px 6px;
}

.filter-search .search-box:deep(.v-label) {
  line-height: 16px;
}

.filter-search .search-box:deep(.v-field__prepend-inner) {
  padding-top: 12px;
}

.filter-search .search-box:deep(.v-field__outline__start) {
  border-radius: 4px 0 0 4px;
}

.v-btn:deep(.v-icon) {
  color: #ffffff;
}

.v-btn:deep(.v-btn__content) {
  color: #ffffff;
}

td .v-btn:deep(.v-icon) {
  font-size: 18px;
}

.v-btn.bg-default:deep(.v-btn__content) {
  color: #000000;
}

.v-input:deep(.v-field:hover .v-field__outline__start) {
  border-color: #2f74bf;
}

.v-input:deep(.v-field:hover .v-field__outline__notch::before) {
  border-color: #2f74bf;
}

.v-input:deep(.v-field:hover .v-field__outline__notch::after) {
  border-color: #2f74bf;
}

.v-input:deep(.v-field:hover .v-field__outline__end) {
  border-color: #2f74bf;
}

.v-table:deep(.v-data-table-footer) {
  padding-bottom: 12px;
}

.fixed-action:deep(table th:last-of-type) {
  right: 0;
  position: sticky;
}
.fixed-action:deep(table td:last-of-type) {
  right: 0;
  position: sticky;
}

@media (min-width: 350px) and (max-width: 650px) {
  .content-wrapper {
    padding: 80px 15px 15px;
  }

  .table-card {
    padding: 10px 10px;
    white-space: nowrap;
  }

  .table-card .v-table:deep(table th) {
    font-size: 14px;
  }

  .table-card .v-table:deep(table td) {
    font-size: 14px;
  }

  .table-card .v-table:deep(.v-data-table-footer) {
    padding-left: 0;
    padding-right: 0;
    padding-top: 15px;
    padding-bottom: 12px;
  }

  .table-card .v-table:deep(.v-data-table-footer .v-data-table-footer__items-per-page) {
    width: 100%;
    padding: 0 15px 5px 15px;
  }

  .table-card .v-table:deep(.v-data-table-footer .v-data-table-footer__items-per-page span) {
    flex: 1;
  }

  .table-card .v-table:deep(.v-data-table-footer .v-data-table-footer__items-per-page .v-input) {
    max-width: 100px;
  }

  .table-card .v-table:deep(.v-data-table-footer .v-data-table-footer__info) {
    padding: 0 6px;
  }
}
</style>
