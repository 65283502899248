<template>
  <div class="layout">
    <TopBar :is-drawer="drawerOpen" @toggle-sidebar="toggleSidebar" />
    <SideBar :drawer="drawerOpen" @toggle-sidebar="toggleSidebar" />
    <div class="route-container">
      <router-view />
    </div>
  </div>
</template>

<script>
import { TopBar, SideBar } from "@/components";

export default {
  name: "LayoutWithNav",
  components: {
    TopBar,
    SideBar,
  },
  data: () => ({
    drawer: false,
  }),
  computed: {
    drawerOpen() {
      return this.drawer;
    },
  },
  methods: {
    toggleSidebar(drawerState) {
      this.drawer = drawerState;
    },
  },
};
</script>
