<template>

  <div class="print-wrapper">
    <PrescriptionPrintTemplateDefault v-if="drprofile && (!drprofile.prescriptiontemplate || drprofile.prescriptiontemplate == 'Default')"
      :drprofile="drprofile"  
      :visitSummary="visitSummary"
      :patient="patient"
      :diagnosis="diagnosis"
      :prescMeds="prescMeds"
      :visit_history="visit_history"
      :visit_pasthistory="visit_pasthistory"
      :visit_examination="visit_examination"
      :visit_investigations="visit_investigations"
      :visit_furtherinvestigationslist="visit_furtherinvestigationslist"
      :visit_furtherinvestigations="visit_furtherinvestigations"
      :visit_nextvisit="visit_nextvisit"
      :computedCredentials="computedCredentials"
      :medfrequencies="medfrequencies"
      :visit_precautions="visit_precautions"
      :visit_comorbidities="visit_comorbidities"
      :visit_treatment="visit_treatment"
    />  
    <PrescriptionPrintTemplate1 v-else-if="drprofile && drprofile.prescriptiontemplate == 'Template1'"
      :drprofile="drprofile"  
      :visitSummary="visitSummary"
      :patient="patient"
      :diagnosis="diagnosis"
      :prescMeds="prescMeds"
      :visit_history="visit_history"
      :visit_pasthistory="visit_pasthistory"
      :visit_examination="visit_examination"
      :visit_investigations="visit_investigations"
      :visit_furtherinvestigationslist="visit_furtherinvestigationslist"
      :visit_furtherinvestigations="visit_furtherinvestigations"
      :visit_nextvisit="visit_nextvisit"
      :computedCredentials="computedCredentials"
      :medfrequencies="medfrequencies"
      :visit_precautions="visit_precautions"
      :visit_comorbidities="visit_comorbidities"
      :visit_treatment="visit_treatment"
    />
    <PrescriptionPrintLeftTopBlank v-else-if="drprofile && drprofile.prescriptiontemplate == 'LeftTopBlank'"
      :drprofile="drprofile"  
      :visitSummary="visitSummary"
      :patient="patient"
      :diagnosis="diagnosis"
      :prescMeds="prescMeds"
      :visit_history="visit_history"
      :visit_pasthistory="visit_pasthistory"
      :visit_examination="visit_examination"
      :visit_investigations="visit_investigations"
      :visit_furtherinvestigationslist="visit_furtherinvestigationslist"
      :visit_furtherinvestigations="visit_furtherinvestigations"
      :visit_nextvisit="visit_nextvisit"
      :computedCredentials="computedCredentials"
      :medfrequencies="medfrequencies"
      :visit_precautions="visit_precautions"
      :visit_comorbidities="visit_comorbidities"
      :visit_treatment="visit_treatment"
    />    
    <div class="printdiv">
      <v-select label="Prescription Template" variant="outlined" color="primary" v-model="drprofile.prescriptiontemplate" :items="['Default', 'Template1', 'LeftTopBlank']"></v-select>
        <v-btn color="primary" @click="print">Print</v-btn>
        <v-btn color="primary" class="ml-10" @click="closeWindow">Close window</v-btn>
        <v-btn color="primary" class="ml-10" @click="scrshot">Send to WhatsApp</v-btn>
    </div>
  </div>

</template>

<script>
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useRoute } from "vue-router";
import { liveQuery } from "dexie";
import { useObservable } from "@vueuse/rxjs";
import { db } from "../../db";
import moment from "moment";
import { medroutesUrdu, dosagesUrdu, durationsUrdu, timingsUrdu, visitFields, drugs } from "@/config"
import { PrescriptionPrintTemplateDefault, PrescriptionPrintTemplate1, PrescriptionPrintLeftTopBlank } from "@/components"
import { post } from "@/apiClient"
export default {
  name: "PrescriptionPrint",
  components: {
    PrescriptionPrintTemplateDefault,
    PrescriptionPrintTemplate1,
    PrescriptionPrintLeftTopBlank
  },
  mounted() {
    this.loadPrescMeds();
    let linkkey = this.$route.params.linkkey;
    db.appobjects.get(linkkey + "_profile").then((obj) => {
      if (obj) {
        this.drprofile = obj.profile;
      }
    });
  },
  data: () => ({
    prescMeds: [],
    drprofile: {
      title: "",
      name: "",
      qualification: "",
      credentials: "",
    },
  }),
  methods: {
    sendEhatsApp (phonenumber) {
      let printContent = document.querySelector(".print-content");
      html2canvas(printContent).then((canvas) => {
        // download the image
        // let link = document.createElement("a");
        // link.download = "download.jpeg";
        let linkkey = this.$route.params.linkkey;
        
        if (!phonenumber) return;

        if (phonenumber.startsWith("03")) {
            phonenumber = "+92" + phonenumber.substring(1);
        }
        if (!phonenumber.startsWith("+")) {
            phonenumber = "+" + phonenumber;
        }

        // check if phone number is valid from pakistan or canada
        if (!phonenumber.startsWith("+92") && !phonenumber.startsWith("+1")) {
            alert("Invalid phone number");
            return;
        }
        
        post(`/${linkkey}/whatsapp/sendprescription`, {image: canvas.toDataURL("image/jpeg"), phonenumber}).then((res)=>{
          console.log(res);
        })
      });

    },
    scrshot() {
      //div with class print-content, take a sceenshot
      this.drprofile.prescriptiontemplate = "Default";
      let phonenumber = prompt("Enter whatsapp phone number", this.patient.phonenumber || "");
      setTimeout(() => {
        this.sendEhatsApp(phonenumber);
      }, 500);
      
    },
    drugStatementUrdu (pd) {
        let uduration = durationsUrdu.find(d=>d.english==pd.durationtype);
        let urdu = `   `;
        let urdudosage = dosagesUrdu.find(d=>d.english==pd.dosage);
        
        if (urdudosage) {
            urdudosage = urdudosage.urdu;
        } else {
            urdudosage = pd.dosage
        }
        if (pd.morning) {
            urdu += ` ${pd.morning} ${urdudosage?urdudosage:""} ${timingsUrdu.morning}, `;
        }
        if (pd.afternoon) {
            urdu += `${pd.afternoon} ${urdudosage?urdudosage:""} ${timingsUrdu.afternoon}, `;
        }
        if (pd.evening) {
            urdu += `${pd.evening} ${urdudosage?urdudosage:""} ${timingsUrdu.evening}, `;
        }
        if (pd.night) {
            urdu += `${pd.night} ${urdudosage?urdudosage:""} ${timingsUrdu.night}, `;
        }
        urdu = urdu.substring(0, urdu.length - 2);
        urdu += ` - ${pd.medfrequency? this.medfrequencies?.find(x => x.key === pd.medfrequency).urdu :""}  `;
        urdu += ` - ${pd.duration?pd.duration:""} ${uduration ? uduration.urdu : pd.durationtype} `
        return urdu;
    },
    drugStatementEnglish ( pd ) {
        let english = ``
        if (pd.morning) {
            english += `${pd.morning} ${pd.dosage?pd.dosage:""} Morning, `;
        }
        if (pd.afternoon) {
            english += `${pd.afternoon} ${pd.dosage?pd.dosage:""} Afternoon, `;
        }
        if (pd.evening) {
            english += `${pd.evening} ${pd.dosage?pd.dosage:""} Evening, `;
        }
        if (pd.night) {
            english += `${pd.night} ${pd.dosage?pd.dosage:""} Night, `;
        }
        //remove last comma
        english = english.substring(0, english.length - 2);
        english += ` - ${pd.medfrequency? this.medfrequencies?.find(x => x.key === pd.medfrequency)?.name :""}  `;
        english += ` - ${pd.duration?pd.duration:""} ${ pd.durationtype} `;
        return english;
    },
    async loadPrescMeds() {
      const route = useRoute();
      const { visitkey: parentid, patientkey } = route.params;
      db.appobjects
        .get({ parentid, type: "visit_medicines" })
        .then(async (data) => {
          if (!data) return;
          let meddetail = JSON.parse(data.detail);
          for (let i = 0; i < meddetail.length; i++) {
              meddetail[i].drug = drugs.find(x=>x.key==meddetail[i].medid)?.name;
          }
          let unnamed = meddetail.filter(x=>!x.drug);
          for (let i = 0; i < unnamed.length; i++) {
            let drg = await db.appobjects.where({key: unnamed[i].medid}).first();
            if (drg) {
                unnamed[i].drug = drg.name;
            }
          }
          this.prescMeds = meddetail;
        });
    },
    formatVisitDate(date) {
      return moment(date).format("Do MMMM YYYY, h:mm a");
    },
    closeWindow() {
      window.close();
    },
    ageFromDate(date) {
      if (!date) return "";
      return moment().diff(date, "years");
    },
    print() {
      window.print();
      //window.onfocus = function () { setTimeout(function () { window.close(); }, 500); }
      // window.close();
    },
    getField(type) {
      if (!this.visitData) return "";
      let field = this.visitData.find((obj) => obj.type === type);
      return field ? field.detail : "";
    },
  },
  computed: {
    computedCredentials() {
      return this.drprofile?.credentials?.split("\n") || [];
    },    
    visit_history() {
      let history = this.getField(visitFields.visit_history);
      return history ? history.replace(/\n/g, ", ") : "";
    },
    visit_pasthistory() {
      let history = this.getField(visitFields.visit_pasthistory);
      return history ? history.replace(/\n/g, ", ") : "";
    },
    visit_examination() {
      let history = this.getField(visitFields.visit_examination);
      return history ? history.replace(/\n/g, ", ") : "";
    },
    visit_diagnosis() {
      let history = this.getField(visitFields.visit_diagnosis);
      return history ? history.replace(/\n/g, ", ") : "";
    },
    visit_investigations() {
      let history = this.getField(visitFields.visit_investigations);
      return history ? history.replace(/\n/g, ", ") : "";
    },
    visit_furtherinvestigations() {
      let history = this.getField(visitFields.visit_furtherinvestigations);
      return history ? history.replace(/\n/g, ", ") : "";
    },
    visit_furtherinvestigationslist() {
        if (!this.visitData) return "";
        if (!this.investigations) return "";
        let vd = this.visitData.find((x) => x.type === "visit_furtherinvestigationslist")
        if (!vd) return "";
        let vdd = JSON.parse(vd.detail)
        for (let i = 0; i < vdd.length; i++) {
            let itm = this.investigations.find((x) => x.key === vdd[i])?.name
            vdd[i] = itm;
        }
        return vdd.join(", ");  
    },
    visit_treatment() {
      let history = this.getField(visitFields.visit_treatment);
      return history ? history.replace(/\n/g, ", ") : "";
    },
    visit_nextvisit() {
      let nextvisit = this.getField(visitFields.visit_nextvisit);
      return nextvisit ? JSON.parse(nextvisit) : null
    },
    visit_comorbidities() {
        if (!this.visitData) return "";
        if (!this.comorbidities) return "";
        let vd = this.visitData.find((x) => x.type === "visit_comorbidities")
        if (!vd) return "";
        let vdd = JSON.parse(vd.detail)
        for (let i = 0; i < vdd.length; i++) {
            vdd[i] = this.comorbidities.find((x) => x.key === vdd[i])?.name;
        }
        return vdd.join(", ");
    },
    visit_precautions() {
        if (!this.visitData) return [];
        if (!this.precautions) return [];
        let vd = this.visitData.find((x) => x.type === "visit_precautions")
        if (!vd) return "";
        let vdd = JSON.parse(vd.detail)
        for (let i = 0; i < vdd.length; i++) {
            let itm = this.precautions.find((x) => x.key === vdd[i])
            vdd[i] = itm;
        }
        return vdd;        
    },
    diagnosis() {
        if (!this.visitData) return [];
        if (!this.diagnoses) return [];
        let vd = this.visitData.find((x) => x.type === "visit_diagnosis")
        if (!vd) return "";
        let vdd = JSON.parse(vd.detail)
        for (let i = 0; i < vdd.length; i++) {
            vdd[i] = this.diagnoses.find((x) => x.key === vdd[i])?.name;
        }
        return vdd;
    },

  },
  setup() {
    const route = useRoute();
    const { visitkey: parentid, patientkey, linkkey } = route.params;
    return {
      visitData: useObservable(
        liveQuery(() => db.appobjects.where({ parentid }).toArray())
      ),
      visitSummary: useObservable(liveQuery(() => db.appobjects.get(parentid))),
      patient: useObservable(liveQuery(() => db.appobjects.get(patientkey))),
      comorbidities: useObservable(
        liveQuery(() => db.appobjects.where({ linkkey, type: "comorbidity" }).toArray())
      ),
      diagnoses: useObservable(
        liveQuery(() => db.appobjects.where({ linkkey, type: "diagnosis" }).toArray())
      ),
      precautions: useObservable(
        liveQuery(() => db.appobjects.where({ linkkey, type: "precaution" }).toArray())
      ),
      medfrequencies: useObservable(
        liveQuery(() => db.appobjects.where({ linkkey, type: "medfrequency" }).toArray())
      ),
      investigations: useObservable(
        liveQuery(() => db.appobjects.where({ linkkey, type: "investigation" }).toArray())
      ),
    };
  },
};
</script>

<style scoped>

.print-wrapper {
  display: flex;
  padding: 15px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.print-content {
  width: 816px;
  max-width: 816px;
  padding: 10px 15px;
  border-radius: 4px;
  border: 1px solid #cecece;
}
.line-height {
  line-height: 14px;
}
.detail-container {
  display: flex;
  padding-bottom: 5px;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
}
.logo {
  width: 120px;
}
.doc-details h4 {
  color: #585858;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 2px;
}
.doc-details h1 {
  color: #333333;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
}
.doc-details span {
  color: #585858;
  font-size: 12px;
}
.doc-details h4 span {
  color: #000000;
  font-size: 12px;
}
.user-detail {
  display: flex;
  color: #585858;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  margin: 6px 0 6px 0;
  justify-content: space-between;
}
.user-detail span {
  font-weight: 500;
}
.treatment-details {
  display: flex;
  padding-top: 10px;
  position: relative;
}
.complaints-investigation {
  min-width: 250px;
  max-width: 250px;
  padding-right: 10px;
}
.complaints-investigation .investigation-card {
  width: 100%;
  padding: 10px;
  min-height: 65px;
  margin-bottom: 5px;
  border: 1px solid #cecece;
}
.complaints-investigation .investigation-card h2 {
  color: #000000;
  font-size: 12px;
  font-weight: 500;
}
.treatment-advice {
  flex: 1;
  padding-left: 10px;
}
.treatment-advice .investigation-card {
  width: 100%;
  padding: 10px;
  min-height: 70px;
  margin-bottom: 10px;
  border: 1px solid #cecece;
}
.treatment-advice .investigation-card:last-of-type {
  margin-left: 20px;
}
.treatment-advice .investigation-card h2 {
  color: #000000;
  font-size: 12px;
  font-weight: 500;
}
.treatment-card h2 {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
}
.treatment-card .card {
  width: 100%;
  padding: 10px;
  min-height: auto;
  margin-bottom: 10px;
  border: 1px solid #cecece;
}
.treatment-card .card h4 {
  color: #333333;
  font-size: 12px;
  font-weight: 400;
}
.treatment-card .card p {
  font-size: 14px;
  font-weight: 600;
  text-align: right;
  font-family: monospace;
}
.treatment-card .card h3 {
  color: #000000;
  font-size: 12px;
  font-weight: 600;
}
.min-height-80 {
  min-height: 70px !important;
}

@media print {
  .print-content {
    padding: 0;
    border: none;
  }
  .print-wrapper {
    padding: 0;
  }

  .treatment-advice .investigation-card {
    border: none;
  }
.treatment-card .card {
  border: none;
}
.complaints-investigation .investigation-card {
  border: none;
}
.print-wrapper {
  align-items: flex-start;
  /* flex-direction: column; */
}

}

</style>