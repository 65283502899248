<template>
  <div class="content-wrapper">
    <div class="screen-title justify-start mobile-view-column">
      <!--TODO: Asim don't you think it should be Visit ID, time may differ in nano-seconds -->
      <h2>Patient Visit - {{patient?.name}} - {{ visitSummary ? formatVisitDate(visitSummary.visitdate) : "" }}</h2>
      <v-switch
        v-model="visittype"
        inset
        hide-details
        true-value="Surgical Visit"
        false-value="Clinical Visit"
        class="ml-10"
        color="primary"
        @change="saveVisitSummary"
        :label="`${visittype}`"
      ></v-switch>
      <v-btn color="primary" icon @click="loadDBData"><v-icon>mdi-refresh</v-icon></v-btn>
    </div>
    <div v-if="visittype == 'Clinical Visit'" class="form-card">
      <v-row gutters="12" class="pt-4">
        <v-col cols="12" md="12" class="pt-0 pb-0">
          <v-textarea color="primary" v-model="visit_history" label="Presenting Complaints" variant="outlined" rows="3" row-height="25"
            shaped @blur="updateField('visit_history', $event.target.value)"></v-textarea>
        </v-col>
        <v-col cols="12" md="12" class="pt-0 pb-0">
          <v-textarea color="primary" v-model="visit_pasthistory" label="Past medical/ surgical history" variant="outlined" rows="3" row-height="25"
            shaped @blur="updateField('visit_pasthistory', $event.target.value)"></v-textarea>
        </v-col>        
        <v-col cols="12" sm="10" md="11" class="pt-0 pb-0 mobile-view-field">
          <v-autocomplete color="primary" chips closable-chips label="Comorbidities" :items="comorbidity" v-model:search="comorbiditiessearch"
            @update:modelValue="comorbiditiessearch = ''"
            v-model="visit_comorbidities" item-title="name" item-value="key" multiple variant="outlined"></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="2" md="1" class="pt-0 pb-0 text-right mobile-view-btn">
          <v-btn class="mt-10-px" density="compact" color="primary" icon="mdi-plus" size="large"
            @click="addConfigData(configFormTypes.comorbidity)" />
        </v-col>
        <v-col cols="12" md="12" class="pt-0 pb-0">
          <v-expansion-panels class="mb-6 vitals-expansion" color="primary">
            <v-expansion-panel>
              <v-expansion-panel-title expand-icon="mdi-chevron-down">
                Vitals
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-row gutters="12">
                  <v-col cols="12" md="3" class="pt-0 pb-0">
                    <v-text-field color="primary" v-model="visit_vitals.heartrate" label="Pulse heart rate" variant="outlined" @blur="updateField('visit_vitals', visit_vitals)"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" class="pt-0 pb-0">
                    <v-text-field color="primary" v-model="visit_vitals.temperature" label="Temperature (F)" variant="outlined" @blur="updateField('visit_vitals', visit_vitals)"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" class="pt-0 pb-0">
                    <v-text-field color="primary" v-model="visit_vitals.bloodpressure" label="Blood pressure" variant="outlined" @blur="updateField('visit_vitals', visit_vitals)"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" class="pt-0 pb-0">
                    <v-text-field color="primary" v-model="visit_vitals.diastolicbloodpressure" label="Diastolic Blood Pressure" variant="outlined" @blur="updateField('visit_vitals', visit_vitals)"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" class="pt-0 pb-0">
                    <v-text-field color="primary" v-model="visit_vitals.respiratoryrate" label="Respiratory rate" variant="outlined" @blur="updateField('visit_vitals', visit_vitals)"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" class="pt-0 pb-0">
                    <v-text-field color="primary" v-model="visit_vitals.bloodsugar" label="Blood sugar" variant="outlined" @blur="updateField('visit_vitals', visit_vitals)"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" class="pt-0 pb-0">
                    <v-text-field color="primary" v-model="visit_vitals.weight" label="Weight (in kg e.g: 70)" variant="outlined" @blur="updateField('visit_vitals', visit_vitals)"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" class="pt-0 pb-0">
                    <v-text-field color="primary" v-model="visit_vitals.height" label="Height (cm)" variant="outlined" @blur="updateField('visit_vitals', visit_vitals)"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" class="pt-0 pb-0">
                    <v-text-field color="primary" v-model="visit_vitals.bmi" label="Body Mass Index (BMI)" variant="outlined" @blur="updateField('visit_vitals', visit_vitals)"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" class="pt-0 pb-0">
                    <v-text-field color="primary" v-model="visit_vitals.spo2" label="Oxygen Saturation" variant="outlined" @blur="updateField('visit_vitals', visit_vitals)"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" class="pt-0 pb-0">
                    <v-text-field color="primary" v-model="visit_vitals.bsa" label="Body Surface Area (BSA)" variant="outlined" @blur="updateField('visit_vitals', visit_vitals)"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" class="pt-0 pb-0">
                    <div class="d-flex justify-end mb-4">
                      <v-btn color="primary" class="primary-btn pl-4 pr-4" @click="updateField('visit_vitals', visit_vitals)">
                        Save
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>              
        <v-col cols="12" md="12" class="pt-0 pb-0">
          <v-textarea color="primary" v-model="visit_examination" label="Examination" variant="outlined" rows="3"
            row-height="25" shaped @blur="updateField('visit_examination', $event.target.value)"></v-textarea>
        </v-col>
        <v-col cols="12" md="12" class="pt-0 pb-0">
          <v-textarea color="primary" v-model="visit_investigations" label="Investigations" variant="outlined" rows="3"
            row-height="25" shaped @blur="updateField('visit_investigations', $event.target.value)"></v-textarea>
        </v-col>
        <v-col cols="12" md="12" class="pt-0 pb-0">
          <v-textarea color="primary" v-model="visit_furtherinvestigations" label="Further Investigations"
            variant="outlined" rows="3" row-height="25" shaped @blur="
              updateField('visit_furtherinvestigations', $event.target.value)
              "></v-textarea>
        </v-col>
        <v-col cols="12" sm="10" md="11" class="pt-0 pb-0 mobile-view-field">
          <v-autocomplete color="primary" chips closable-chips label="Further Investigations" :items="investigation" item-title="name"
            v-model:search="investigationsearch" @update:modelValue="investigationsearch = ''"
            item-value="key" v-model="visit_furtherinvestigationslist" multiple variant="outlined"></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="2" md="1" class="pt-0 pb-0 text-right mobile-view-btn">
          <v-btn class="mt-10-px" density="compact" color="primary" icon="mdi-plus" size="large"
            @click="addConfigData(configFormTypes.investigation)" />
        </v-col>

        <v-col cols="12" sm="10" md="11" class="pt-0 pb-0 mobile-view-field">
          <v-autocomplete color="primary" chips closable-chips label="Diagnosis" :items="diagnosis" item-title="name"
            v-model:search="diagnosissearch" @update:modelValue="diagnosissearch = ''"
            item-value="key" v-model="visit_diagnosis" multiple variant="outlined"></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="2" md="1" class="pt-0 pb-0 text-right mobile-view-btn">
          <v-btn class="mt-10-px" density="compact" color="primary" icon="mdi-plus" size="large"
            @click="addConfigData(configFormTypes.diagnosis)" />
        </v-col>
        <v-col cols="12" md="12" class="pt-0 pb-0">
          <v-textarea color="primary" v-model="visit_treatment" label="Treatment" variant="outlined" rows="3"
            row-height="25" shaped @blur="updateField('visit_treatment', $event.target.value)"></v-textarea>
        </v-col>
        <!-- /// Meds Table /// -->
        <v-col cols="12" md="12" class="pt-0 pb-0">
          <!--TODO: Zubair we do not need pagination here find the solution and remove it, below link may help-->
          <!--https://vuetifyjs.com/en/api/v-data-table/-->
          <v-col cols="12" md="12" class="pa-0">
            <v-expansion-panels class="mb-6 table-expansion" color="primary">
              <v-expansion-panel>
                <v-expansion-panel-title expand-icon="mdi-chevron-down">
                  Prescription
                </v-expansion-panel-title>
                <v-expansion-panel-text>
          <v-autocomplete color="primary" v-if="prescriptionPresets.length" label="Prescription Preset" :items="prescriptionPresets" item-title="name"
            item-value="key" v-model="prescriptionPreset" variant="outlined"></v-autocomplete>                      
                  <v-data-table class="medicines-table">
                    <!-- <thead>
                      <tr>
                        <th class="text-left">
                          Medicine
                        </th>
                        <th class="text-left">
                          Duration
                        </th>
                        <th class="text-left">
                          Dosage
                        </th>
                        <th class="text-left">
                          Morning
                        </th>
                        <th class="text-left">
                          Afternoon
                        </th>
                        <th class="text-left">
                          Evening
                        </th>
                        <th class="text-left">
                          Night
                        </th>
                        <th class="text-left">
                          Note
                        </th>
                        <th class="text-left">
                          Action
                        </th>
                      </tr>
                    </thead> -->
                    <tbody>
                      <tr v-for="(pMedicine, index) in prescribedMedicines" :key="pMedicine.key">
                        <div class="custom-table">
                          <div>
                            <td>
                              <v-autocomplete label="Medicine" :items="medicine" v-model="pMedicine.medid" :ref="'medsinpres' + index"
                                item-title="name" item-value="key" variant="outlined" @keyup="drugSearch">
                              </v-autocomplete>
                              <!-- <v-select color="primary" label="Medicine" v-model="pMedicine.name" :items="medicine"
                            item-title="name" item-value="name" variant="outlined"></v-select> -->
                            </td>
                          </div>

                          <div>
                            <td>
                              <v-text-field variant="outlined" label="Duration" v-model="pMedicine.duration">

                              </v-text-field>
                              <v-autocomplete label="Duration Type" :items="durations" v-model="pMedicine.durationtype"
                                variant="outlined">

                              </v-autocomplete>
                            </td>
                          </div>

                          <div>
                            <td>
                              <v-autocomplete label="Presc Preset" variant="outlined"
                                v-model="pMedicine.prescriptiondefault" item-title="name" item-value="key"
                                :items="prescriptionDefaults.filter((x) => { return x.medtypes.includes(alldrugs.find(d => d.key === pMedicine.medid)?.type )})" @blur="setPrescriptionDefaults(pMedicine)"
                                @change="setPrescriptionDefaults(pMedicine)">
                              </v-autocomplete>
                            </td>
                          </div>

                          <div>
                            <td>
                              <v-autocomplete label="Dosage Type" :items="dosages" v-model="pMedicine.dosage"
                                variant="outlined">
                              </v-autocomplete>
                            </td>
                          </div>

                          <div>
                            <td>
                              <v-autocomplete label="Frequency" variant="outlined"
                                              v-model="pMedicine.medfrequency" item-title="name" item-value="key"
                                              :items="medFrequencies.filter((x) => { return x.visible})">
                              </v-autocomplete>
                            </td>
                          </div>

                          <div>
                            <td>
                              <v-text-field variant="outlined" label="Morning" type="number" v-model="pMedicine.morning">

                              </v-text-field>
                            </td>
                          </div>

                          <div>
                            <td>
                              <v-text-field variant="outlined" label="Afternoon" type="number"
                                v-model="pMedicine.afternoon">

                              </v-text-field>
                            </td>
                          </div>

                          <div>
                            <td>
                              <v-text-field variant="outlined" label="Evening" type="number" v-model="pMedicine.evening">

                              </v-text-field>
                            </td>
                          </div>

                          <div>
                            <td>
                              <v-text-field variant="outlined" label="Night" type="number" v-model="pMedicine.night">

                              </v-text-field>
                            </td>
                          </div>

                          <div class="route-select">
                            <v-select  variant="outlined" class="" v-model="pMedicine.route" :items="medroutes" label="Route"></v-select>
                            <!-- <td>
                              <v-text-field color="primary" label="Route" variant="outlined"
                                v-model="pMedicine.route"></v-text-field>
                            </td> -->
                          </div>

                          <div>
                            <td>
                              <v-text-field color="primary" label="Note" v-model="pMedicine.note"
                                variant="outlined"></v-text-field>
                            </td>
                          </div>

                          <div>
                            <td>
                              <v-btn color="primary" class="h-46 mt-1" @click="removeMedicineFromPrescription(index)">
                                <v-icon class="mdi mdi-trash-can-outline mr-1"></v-icon>
                              </v-btn>
                            </td>
                          </div>

                        </div>
                      </tr>
                      <tr class="action-row">
                        <div class="custom-table-btn">
                          <div>
                            <td>
                              <v-btn color="primary" class="w-100 h-46" @click="addMedicineInPrescription">
                                <v-icon class="mdi mdi-plus"></v-icon>
                                Add Medicine in prescription
                              </v-btn>
                            </td>
                          </div>
                          <div>
                            <td>
                              <v-btn color="primary" class="w-100 h-46" @click="savePrescription">
                                <v-icon class="mdi mdi-content-save"></v-icon>
                                Save
                              </v-btn>
                            </td>
                          </div>                          
                          <div>
                            <td>
                              <v-btn color="primary" class="w-100 h-46" @click="printPrescription">
                                <v-icon class="mdi mdi-printer-wireless mr-1"></v-icon>
                                Print
                              </v-btn>
                            </td>
                          </div>
                          <!-- <div>
                            <td>
                              <v-btn color="primary" class="w-100 h-46" @click="addConfigData(configFormTypes.medicine)">
                                <v-icon class="mdi mdi-plus mr-1"></v-icon>
                                Add New Medicine
                              </v-btn>
                            </td>
                          </div> -->
                          <div>
                            <td>
                              <v-btn color="primary" class="w-100 h-46" @click="savePrescriptionPreset">
                                <v-icon class="mdi mdi-content-save mr-1"></v-icon>
                                Save as Preset
                              </v-btn>
                            </td>
                          </div>                          
                        </div>
                      </tr>
                    </tbody>
                  </v-data-table>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-col>
        <!-- /// Meds Table /// -->
        <v-col cols="12" sm="4" md="3" lg="2" class="pt-0 pb-0 mobile-view-precaution">
          <v-autocomplete color="primary" v-if="precautionDefaults.length" label="Choose Precaution Set" :items="precautionDefaults" item-title="name"
            item-value="key" v-model="precautionDefault" variant="outlined" @blur="fillPrecautions"></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="7" lg="9" class="pt-0 pb-0 mobile-view-precaution">
          <v-autocomplete color="primary" chips closable-chips label="Precautions" :items="precaution" item-title="name"
            item-value="key" v-model="visit_precautions" multiple variant="outlined"></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="2" md="2" lg="1" class="pt-0 pb-0 pl-0 text-right mobile-view-btn-precaution">
          <v-btn class="mt-10-px mr-3" density="compact" color="primary" icon="mdi-content-save" size="large" @click="savePrecautionDefault"></v-btn>
          <v-btn class="mt-10-px" density="compact" color="primary" icon="mdi-plus" size="large"
            @click="addConfigData(configFormTypes.precaution)" />
        </v-col>
        <v-col cols="12" md="12" class="pt-0 pb-0">
          <label>Next Visit</label>
        </v-col>        
        <v-col cols="12" md="6" class="pt-0 pb-0">
          <v-text-field type="number" variant="outlined" v-model="visit_nextvisit.number" @blur="updateField('visit_nextvisit', visit_nextvisit)" label="Next Visit">

          </v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="pt-0 pb-0">
          <v-autocomplete color="primary" label="D/W/M/Y" v-model="visit_nextvisit.dwmy" @blur="updateField('visit_nextvisit', visit_nextvisit)" :items="visitFollowups"
            variant="outlined" ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="12" class="pt-0 pb-0">
          <label>Visit Images</label>
          <v-file-input @change="fileChange" multiple v-model="file" label="Visit Images" accept="image/*"></v-file-input>
        </v-col>
        <v-col cols="12" md="12" class="pt-0 pb-0 images-wrapper">
          <div class="mr-3 position-relative" v-for="img of images" :key="img.key">
            <v-btn class="delete-image" icon="mdi-close" size="small" color="primary"
                   @click="dialog = true"></v-btn>
            <img :src="img.imagedata" width="200" alt="" @click="imageClick(img)" />
          </div>
        </v-col>
        <v-col cols="12" md="12" class="d-flex justify-end mb-3">
          <v-btn color="primary" @click="printPrescription">
            <v-icon class="mdi mdi-printer-wireless mr-1"></v-icon>
            Print
          </v-btn>          
          <v-btn color="default" class="ml-4" @click="goToPatientVisits">
            Cancel
          </v-btn>
          <v-btn color="primary" @click="goToPatientVisits" class="ml-4">
            Save
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-else class="form-card">
      <v-row gutters="12" class="pt-4">
        <v-col cols="12" md="12" class="pt-0 pb-0">
          <v-textarea color="primary" v-model="visit_surgicalnotes" label="Surgical Notes" variant="outlined" rows="3" row-height="25"
            shaped @blur="updateField('visit_surgicalnotes', $event.target.value)"></v-textarea>
        </v-col>
        <v-col cols="12" md="12" class="pt-0 pb-0">
          <label>Visit Images</label>
          <v-file-input @change="fileChange" multiple v-model="file" label="Visit Images" accept="image/*"></v-file-input>
        </v-col>
        <v-col cols="12" md="12" class="pt-0 pb-0 images-wrapper">
          <div class="mr-3 position-relative" v-for="img of images" :key="img.key">
            <v-btn class="delete-image" icon="mdi-close" size="small" color="primary"
                   @click="dialog = true"></v-btn>
            <img :src="img.imagedata" width="200" alt="" @click="imageClick(img)" />
          </div>
        </v-col>
        <v-col cols="12" md="12" class="d-flex justify-end mb-3">       
          <v-btn color="default" class="ml-4" @click="goToPatientVisits">
            Cancel
          </v-btn>
          <v-btn color="primary" @click="goToPatientVisits" class="ml-4">
            Save
          </v-btn>
        </v-col>        
      </v-row>
    </div>
    <!-- //// Modal //// -->
    <!--TODO: Salik Need to find a good way to pass function/method as props-->
    <ConfigForm v-if="configDataType" :type="configDataType" :closeHandler="onConfigDialogClose" />

    <!-- //// Modal //// -->

    <!-- Image Delete Modal -->
    <v-dialog
        v-model="dialog"
        width="500"
    >
      <v-card>
        <v-card-text>
          <v-btn class="close-modal" icon="mdi-close" color="primary"
                 @click="dialog = false" size="small"></v-btn>
          <p class="image-paragraph">
            <strong>Are You Sure!</strong> <br>
            You want to delete this image.
          </p>
          <div class="d-flex align-center justify-center mb-2">
            <v-btn class="mr-2 ml-2 danger-btn" @click="dialog = false">No</v-btn>
            <v-btn color="primary" class="mr-2 ml-2">Yes</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Image Delete Modal -->
  </div>
</template>

<script>
import { groupBy } from "lodash";
import { useRoute, useRouter } from "vue-router";
import { liveQuery } from "dexie";
import { useObservable } from "@vueuse/rxjs";
import { VDataTable } from "vuetify/labs/VDataTable";
import { ConfigForm } from "@/components";
import { configFormTypes, visitFields, durations, dosages, medroutes, visitFollowups, drugs } from "@/config";
import { db } from "../../db";
import _ from "lodash";
import { get } from "@/apiClient"
import axios from 'axios';
import moment from 'moment';

export default {
  name: "PatientVisit",
  components: {
    VDataTable,
    ConfigForm,
  },
  setup() {
    const route = useRoute();
    const { visitkey: parentid, patientkey, linkkey } = route.params;
    return {
      // visitData: useObservable(
      //   liveQuery(() => db.appobjects.where({ parentid }).toArray())
      // ),
      // patient: useObservable(
      //   liveQuery(() => db.appobjects.get(patientkey))
      // ),
      // visitSummary: useObservable(
      //   liveQuery(() => db.appobjects.get(parentid))
      // ),
      // medicine: useObservable(
      //   liveQuery(() =>
      //     db.drugs
      //       .toArray()
      //   )
      // ),
      // comorbidity: useObservable(
      //   liveQuery(() => db.appobjects.where({ visible: true }).toArray())
      // ),
      // diagnosis: useObservable(
      //   liveQuery(() => db.appobjects.where({ type: "diagnosis" }).toArray())
      // ),  
      // precaution: useObservable(
      //   liveQuery(() => db.appobjects.where({ type: "precaution" }).toArray())
      // ),            
    };
  },
  data: () => ({
    loadingimages: false,
    dialog: false,
    visitData: [],
    patient: null,
    visitSummary: null,
    pdefaultvalue: "",
    configDataType: null,
    precautionDefaults: [],
    prescriptionDefault: null,
    prescriptionPresets: [],
    prescriptionPreset: null,
    prescriptionDefaults: [],
    medicine: [],
    alldrugs: [],
    comorbidity: [],
    diagnosis: [],
    precaution: [],
    investigation: [],
    dosage: [],
    frequency: [],
    note: [],
    medFrequencies: [],
    prescribedMedicines: [
    ],
    file: null,
    images: [],
    printDialog: false,
    refreshInterval: null,
    comorbiditiessearch: "",
    investigationsearch: "",
    diagnosissearch: ""
  }),
  computed: {
    // prescriptionDefaults() {
    //   return prescriptionDefaults;
    // },
    visitFollowups() {
      return visitFollowups;
    },
    durations() {
      return durations;
    },
    dosages() {
      return dosages;
    },
    medroutes() {
      return medroutes;
    },
    visittype: {
      get() {
        return this.visitSummary?.visittype || "Clinical Visit";
      },
      set: function (val) {
        let summobj = JSON.parse(JSON.stringify(this.visitSummary));
        summobj.visittype = val;
        summobj.modified = Date.now();
        summobj.synced = 0;
        db.appobjects.put(summobj);
      }
    },
    visit_history: {
      get() {
        return this.getField(visitFields.visit_history);
      },
      set: _.debounce(function (val) {
        this.updateField(visitFields.visit_history, val);
      }, 2000),
    },
    visit_pasthistory: {
      get() {
        return this.getField(visitFields.visit_pasthistory);
      },
      set: _.debounce(function (val) {
        this.updateField(visitFields.visit_pasthistory, val);
      }, 2000),
    },
    visit_examination: {
      get() {
        return this.getField(visitFields.visit_examination);
      },
      set: _.debounce(function (val) {
        this.updateField(visitFields.visit_examination, val);
      }, 2000),
    },
    visit_investigations: {
      get() {
        return this.getField(visitFields.visit_investigations);
      },
      set: _.debounce(function (val) {
        this.updateField(visitFields.visit_investigations, val);
      }, 2000),
    },
    visit_furtherinvestigations: {
      get() {
        return this.getField(visitFields.visit_furtherinvestigations);
      },
      set: _.debounce(function (val) {
        this.updateField(visitFields.visit_furtherinvestigations, val);
      }, 2000),
    },
    visit_furtherinvestigationslist: {
      get() {
        const data = this.getField(visitFields.visit_furtherinvestigationslist);
        return data ? JSON.parse(data) : [];
      },
      set(val) {
        this.updateField(visitFields.visit_furtherinvestigationslist, val);
      },
    },
    // visitSummaryComputed: {
    //   get() {
    //     if (!this.visitSummary) return null;
    //     if (!this.visitSummary.visittype) return "Clinical Visit";
    //     return this.visitSummary;
    //   },
    //   set(val) {
    //     console.log(val);
    //     // this.updateField(visitFields.visit_furtherinvestigationslist, val);
    //   },      

    // },    
    visit_treatment: {
      get() {
        return this.getField(visitFields.visit_treatment);
      },
      set: _.debounce(function (val) {
        this.updateField(visitFields.visit_treatment, val);
      }, 2000),
    },
    visit_surgicalnotes: {
      get() {
        return this.getField(visitFields.visit_surgicalnotes);
      },
      set: _.debounce(function (val) {
        this.updateField(visitFields.visit_surgicalnotes, val);
      }, 2000),
    },
    visit_nextvisittimedistance: {
      get() {
        return this.getField(visitFields.visit_nextvisittimedistance);
      },
      set: _.debounce(function (val) {
        this.updateField(visitFields.visit_nextvisittimedistance, val);
      }, 2000),
    },
    visit_comorbidities: {
      get() {
        const data = this.getField(visitFields.visit_comorbidities);
        return data ? JSON.parse(data) : [];
      },
      set(val) {
        this.updateField(visitFields.visit_comorbidities, val);
      },
    },
    visit_precautions: {
      get() {
        const data = this.getField(visitFields.visit_precautions);
        return data ? JSON.parse(data) : [];
      },
      set(val) {
        this.updateField(visitFields.visit_precautions, val);
      },
    },
    visit_vitals: {
      get() {
        const data = this.getField(visitFields.visit_vitals);
        return data ? JSON.parse(data) : {
          heartrate: null,
          temperature: null,
          bloodpressure: null,
          diastolicbloodpressure: null,
          respiratoryrate: null,
          weight: null,
          height: null,
          bmi: null,
          spo2: null,
          bloodsugar: null,
          bsa: null,
        };
      },
      set(val) {
        this.updateField(visitFields.visit_vitals, val);
      },
    },
    visit_nextvisit: {
      get() {
        const data = this.getField(visitFields.visit_nextvisit);
        return data ? JSON.parse(data) : {
          number: null,
          dwmy: "Weeks",
        };
      },
      set: _.debounce(function (val) {
        this.updateField(visitFields.visit_nextvisit, val);
      }, 0),
    },
    visit_diagnosis: {
      get() {
        const data = this.getField(visitFields.visit_diagnosis);
        return data ? JSON.parse(data) : [];
      },
      set(val) {
        this.updateField(visitFields.visit_diagnosis, val);
      },
    },
    visit_images: {
      get() {
        let data = this.getField(visitFields.visit_images);
        return data ? JSON.parse(data) : [];
      },
      set(val) {
        this.updateField(visitFields.visit_images, val);
      },
    },
    visit_medicines: {
      get() {
        return this.prescribedMedicines
      },
      set(val) {
        this.updateField(visitFields.visit_medicines, val)
      }
    },
    configFormTypes() {
      return configFormTypes;
    },
    visitFields() {
      return visitFields;
    },
  },
  async mounted() {
    this.loadDBData();
    // TODO: This query fetching all records from DB we need to do clinic wise.
    let configTypes = Object.keys(configFormTypes);
    let linkkey = this.$route.params.linkkey;
    configTypes = configTypes.map((key) => [linkkey, key]);
    let data = await db.appobjects.where("[linkkey+type]").anyOf(configTypes).toArray();
    data = data.filter((item) => item.visible && item.linkkey == linkkey);
    // if item includes icdcodes then add whoicd codes
    data = data.map((item) => {
      if (item.icdcodes) {
        item.name = item.name + " (" + item.icdcodes + ")";
      }
      return item;
    });
    const options = groupBy(data, "type");
    configTypes.forEach((key) => {
      this[key[1]] = options[key[1]] || [];
    });

    let presc = await db.appobjects
      .where({ parentid: this.$route.params.visitkey, type: visitFields.visit_medicines }).first()
    this.prescribedMedicines = presc?.detail ? JSON.parse(presc.detail) : []
    this.loadPrecautionDefaults();
    this.loadPrescriptionPresets();
    let clinicdrugs = await db.appobjects.where({linkkey: linkkey, type: "medicine"}).toArray();
    console.log(drugs.length)
    clinicdrugs = clinicdrugs.concat(drugs)
    clinicdrugs = clinicdrugs.map((item) => {
      return {
        ...item,
        name: item.name + " (" + item.generics + ")",
      };
    });
    this.alldrugs = clinicdrugs;
    db.appobjects
      .where({ linkkey: this.$route.params.linkkey, type: "medtypepreset" })
      .toArray()
      .then((data) => {
        this.prescriptionDefaults = data;
      });
    db.appobjects
      .where({ linkkey: this.$route.params.linkkey, type: "medfrequency" })
      .toArray()
      .then((data) => {
        this.medFrequencies = data;
    });
  },
  unmounted() {
    clearInterval(this.refreshInterval);
    this.images = null;
    // clear all data
    this.visitData = null;
    this.patient = null;
    this.visitSummary = null;
    this.pdefaultvalue = null;
    this.configDataType = null;
    this.precautionDefaults = null;
    this.prescriptionDefault = null;
    this.prescriptionPresets = null;
    this.prescriptionPreset = null;
    this.prescriptionDefaults = null;
    this.medicine = null;
    this.alldrugs = null;
    this.comorbidity = null;
    this.diagnosis = null;
    this.precaution = null;
    this.investigation = null;
    this.dosage = null;
    this.frequency = null;
    this.note = null;
    this.medFrequencies = null;
    this.prescribedMedicines = null;
    this.file = null;
    this.images = null;
    this.printDialog = null;
    this.refreshInterval = null;

  },
  watch: {
    visit_medicines: {
      handler: function (val) {
        this.fillDrugArray(val)
      },
      deep: true,
    },
    prescriptionPreset: {
      handler: function () {
        this.fillFromPrescriptionPreset();
      },
      deep: true
    },
    visit_images: {
      handler: function (val) {
        if (!this.loadingimages) {
          this.loadVisitImages();
          console.log("loading images")
        }
      },
      deep: true
    }
  },
  methods: {
    loadDBData () {
      // const route = useRoute();

      const { visitkey: parentid, patientkey, linkkey } = this.$route.params;
      // get visitdata
      db.appobjects.where({ parentid }).toArray().then((data) => {
        this.visitData = data;
      })
      // get patient
      db.appobjects.get(patientkey).then((data) => {
        this.patient = data;
      })
      // get visit summary
      db.appobjects.get(parentid).then((data) => {
        this.visitSummary = data;
      })
    
    },
    saveVisitSummary( ) {
      this.loadDBData();
    },
    filteredMedTypePresets (med) { 
      return this.prescriptionDefaults
    },
    drugSearch(event) {
      _.debounce(this.fillDrugArray, 200)(this.visit_medicines, event.target.value)
    },
    async fillDrugArray (selecteddrugs = [], searchstring = "") {
        let usedmedkeys = selecteddrugs.map((m) => m.medid)
        if (selecteddrugs.length) {
          usedmedkeys = [...new Set(usedmedkeys)].filter((m) => m)
          let sdrugs = drugs.filter((d) => usedmedkeys.includes(d.key))
          this.medicine = sdrugs
        }
        if (searchstring.length > 2) {
          let srchdrugs = this.alldrugs.filter((d) => d.name.toLowerCase().includes(searchstring.toLowerCase()) )
          let sdrugs = srchdrugs.filter((d) => !usedmedkeys.includes(d.key))
          // limit to 50
          sdrugs = sdrugs.slice(0, 50)
          this.medicine = [...sdrugs, ...this.medicine]
        }
    },
    fillPrecautions() {
      if (!this.precautionDefault) return;
      this.visit_precautions = this.precautionDefaults.find((p) => p.key === this.precautionDefault)?.defaults || [];
      // this.visit_precautions = this.precautionDefaults[0].defaults;
    },
    loadPrecautionDefaults() {
      const { linkkey } = this.$route.params;
      db.appobjects
        .where({ parentid: linkkey, type: "precautiondefault" })
        .toArray()
        .then((data) => {
          this.precautionDefaults = data.filter((d) => d.visible);
        });
    },
    savePrecautionDefault ()  {
      let predefname = prompt("Enter name for this precaution", "Precaution")
      if (!predefname) return;
      const { linkkey } = this.$route.params;
      let pdef = {
        key: "precautiondefault." + Date.now(),
        linkkey,
        name: predefname,
        parentid: linkkey,
        defaults: this.visit_precautions,
        modified: Date.now(),
        visible: true,
        synced: 0,
        type: "precautiondefault",
      }
      db.appobjects.put(pdef).then(() => {
        this.loadPrecautionDefaults()        // this.precautionDefaults.push({ key, name: predefname })
      })


    },
    printPrescription() {
      this.savePrescription();
      const routeData = this.$router.resolve({ name: "PrescriptionPrint", params: { visitkey: this.$route.params.visitkey, patientkey: this.$route.params.patientkey } })
      window.open(routeData.href, '_blank');
    },
    imageClick(image) {
      var ni = new Image();
      ni.src = image.imagedata
      ni.style.width = "100%";
      var w = window.open("");
      w.document.write(ni.outerHTML);
    },
    setPrescriptionDefaults(prescriptionMed) {
      if (!prescriptionMed.prescriptiondefault) return;
      let def = this.prescriptionDefaults.find((p) => p.key == prescriptionMed.prescriptiondefault)
      if (!def) return;
      prescriptionMed = { ...prescriptionMed, ...def.defaults }
      this.prescribedMedicines = this.prescribedMedicines.map((p) => {
        if (p.key == prescriptionMed.key) {
          return prescriptionMed
        }
        return p
      })
    },
    async loadVisitImages() {
      if (this.loadingimages) return;
      if (this.visit_images) {
        this.loadingimages = true;
        for (let img of this.visit_images) {
          if (!img) continue;
          if (this.images.find((i) => i.key == img)) continue;
          if (img.endsWith(".v2")) {
            let dbimg = await db.images.get(img)
            if (dbimg && dbimg.imagedata && dbimg.synced === 0) {
              this.images.push(dbimg);
            } else {
              if (dbimg && dbimg.imagedata) {
                //parse url for Timestamp querystring and get value
                let url = new URL(dbimg.imagedata)
                let ts = url.searchParams.get("Expires")
                if (ts && ts - Date.now() / 1000 > 10) {
                  this.images.push(dbimg);
                  continue;
                }
              }
            let res = await get(this.$route.params.linkkey + "/visit/image/geturl?imagekey=" + img)
              this.$store.state.db.images.put({
                key: img,
                imagedata: res.data.url,
                modified: Date.now(),
                linkkey: this.$route.params.linkkey,
                synced: 1
              })
              this.images.push({
                key: img,
                imagedata: res.data.url,
                modified: Date.now(),
                linkkey: this.$route.params.linkkey,
                synced: 1
              })
            }
          } else {
            let res = await db.images.get(img)
            if (res) {
              this.images.push(res);
            } else {
              let res = await get(this.$route.params.linkkey + "/visit/image/geturl?imagekey=" + img)
              let resi = await axios.get(res.data.url).catch((err) => {
                console.log(err)
              })
              if (!resi) continue;
              this.$store.state.db.images.put({
                key: img,
                imagedata: resi.data,
                modified: Date.now(),
                linkkey: this.$route.params.linkkey,
                synced: 1
              })
              this.images.push({
                key: img,
                imagedata: resi.data,
                modified: Date.now(),
                linkkey: this.$route.params.linkkey,
                synced: 1
              })
            }
          }
        }
        this.loadingimages = false;

        // this.visit_images.forEach((img) => {
        //   if (!img) return;
        //   if (this.images.find((i) => i.key == img)) return;
        //   this.$store.state.db.images.get(img).then((res) => {
        //     if (res) {
        //       this.images.push(res);
        //     } else {
        //       get(this.$route.params.linkkey + "/visit/image/geturl?imagekey=" + img).then((res) => {
        //         axios
        //           .get(res.data.url)
        //           .then((resi) => {
        //             this.$store.state.db.images.put({
        //               key: img,
        //               imagedata: resi.data,
        //               modified: Date.now(),
        //               linkkey: this.$route.params.linkkey,
        //               synced: 1
        //             });
        //             this.images.push({ key: img, imagedata: resi.data });
        //             // this.images.push(res.data);
        //           })
        //           .catch((err) => {
        //             // console.log(err);
        //           });

        //         // if (res) {
        //         //   this.images.push(res);
        //         // }
        //       });
        //     }
        //   });
        // });
      }
    },
    formatVisitDate(date) {
      return moment(date).format("Do MMMM YYYY, h:mm a");
    },
    getField(type) {
      if (!this.visitData) return "";
      let field = this.visitData.find((obj) => obj.type === type);
      return field ? field.detail : "";
    },
    async fileChange(e) {
      let files = await this.fileListToBase64(e.target.files);
      let prevImages = this.getField(visitFields.visit_images);
      prevImages = prevImages ? JSON.parse(prevImages) : [];
      for (let file of files) {
        let linkkey = this.$route.params.linkkey;
        let patientvisitkey = this.$route.params.visitkey;
        const imagekey = patientvisitkey + ".image." + Date.now() + Math.random() + ".v2";
        this.images.push({ key: imagekey, imagedata: file });
          prevImages.push(imagekey);
          await this.$store.state.db.images.put({
            key: imagekey,
            imagedata: file,
            modified: Date.now(),
            linkkey: linkkey,
            synced: 0
          });
          this.file = null;
      }
      this.updateField(visitFields.visit_images, prevImages);
    },
    async  fileListToBase64(fileList) {
  // create function which return resolved promise
  // with data:base64 string
      function getBase64(file) {
        const reader = new FileReader()
        return new Promise(resolve => {
          reader.onload = ev => {
            resolve(ev.target.result)
          }
          reader.readAsDataURL(file)
        })
      }
      // here will be array of promisified functions
      const promises = []

      // loop through fileList with for loop
      for (let i = 0; i < fileList.length; i++) {
        promises.push(getBase64(fileList[i]))
      }

      // array with base64 strings
      return await Promise.all(promises)
    },
    addConfigData(type) {
      this.configDataType = type;
    },
    onConfigDialogClose(entries) {
      if (!entries) {
        this.configDataType = null;
        return;
      }
      setTimeout(() => {
        switch (this.configDataType) {
          case configFormTypes.comorbidity:
            this.visit_comorbidities.push(...entries.map((e) => e.key ));
            this.updateField(visitFields.visit_comorbidities, this.visit_comorbidities);
            this.comorbidity.push(...entries);
            break;
          case configFormTypes.diagnosis:
            this.visit_diagnosis.push(...entries.map((e) => e.key ));
            this.updateField(visitFields.visit_diagnosis, this.visit_diagnosis);
            this.diagnosis.push(...entries);
            break;
          case configFormTypes.precaution:
            this.visit_precautions.push(...entries.map((e) => e.key ));
            this.updateField(visitFields.visit_precautions, this.visit_precautions);
            this.precaution.push(...entries);
            break;
          case configFormTypes.investigation:
            this.visit_furtherinvestigationslist.push(...entries.map((e) => e.key ));
            this.updateField(visitFields.visit_furtherinvestigationslist, this.visit_furtherinvestigationslist);
            this.investigation.push(...entries);
            break;
        }
        this.configDataType = null;
      }, 200);
      
    },
    addMedicineInPrescription() {
      const newMedicine = {
        key: Date.now(),
        medid: "",
        duration: "1",
        durationtype: "",
        medfrequency: "",
        dosage: "",
        medroute: "",
        morning: "",
        afternoon: "",
        evening: "",
        night: "",
        note: "",
      };

      this.prescribedMedicines.push(newMedicine);
      setTimeout(() => {
        this.$refs["medsinpres" + (this.prescribedMedicines.length - 1)][0].focus();
      }, 200);
    },
    removeMedicineFromPrescription(index) {
      this.prescribedMedicines.splice(index, 1);
    },
    loadPrescriptionPresets () {
      const { linkkey } = this.$route.params;
      db.appobjects
        .where({ parentid: linkkey, type: "prescriptionpreset" })
        .toArray()
        .then((data) => {
          // console.log(data);
          this.prescriptionPresets = data;
        });
    },
    fillFromPrescriptionPreset() {
      if (!this.prescriptionPreset) return;
      let preset = this.prescriptionPresets.find((p) => p.key == this.prescriptionPreset)
      if (!preset) return;
      this.prescribedMedicines = JSON.parse(JSON.stringify(preset.defaults))
      this.savePrescription()
    },
    savePrescriptionPreset() {
      let predefname = prompt("Enter name for this prescription preset", "Prescription Preset")
      if (!predefname) return;
      const { linkkey } = this.$route.params;
      let pdef = {
        key: "prescriptionpreset." + Date.now(),
        linkkey,
        name: predefname,
        parentid: linkkey,
        defaults: JSON.parse(JSON.stringify(this.prescribedMedicines)),
        modified: Date.now(),
        synced: 0,
        type: "prescriptionpreset",
      }
      db.appobjects.put(pdef).then(() => {
        this.loadPrescriptionPresets()        // this.precautionDefaults.push({ key, name: predefname })
      })
    },
    async savePrescription() {
      console.log(this.prescribedMedicines)
      await this.updateField(visitFields.visit_medicines, this.prescribedMedicines)
    },
    async goToPatientVisits() {
      await this.savePrescription();
      const { push } = this.$router;
      const {
        params: { linkkey, patientkey },
      } = this.$route;
      push(`/${linkkey}/dashboard`);
    },
    async updateField(field, value) {
      const { linkkey, visitkey } = this.$route.params;
      const timeStamp = Date.now();
      let existingObj =
        this.visitData.find((obj) => obj && obj.type === field) || {};
      const defaultPayload = {
        key: `${visitkey}.${field}`,
        linkkey,
        parentid: visitkey,
        type: field,
      };
      const payload = {
        ...defaultPayload,
        ...existingObj,
        detail: Array.isArray(value) || typeof value === 'object' ? JSON.stringify(value) : value,
        synced: 0,
        modified: timeStamp,
      };
        await db.appobjects.put(payload);
      existingObj.detail = payload.detail;
      this.loadDBData();
    },
  },
};
</script>

<style scoped>
.content-wrapper {
  padding: 84px 24px 24px;
}

.screen-title {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
}

.screen-title h2 {
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  letter-spacing: 0.5px;
}
.screen-title .v-switch--inset {
  max-width: 200px;
}
.screen-title .v-switch--inset:deep(.v-label) {
  opacity: 1;
  color: #333333;
  font-size: 16px;
}

.form-card {
  padding: 10px 20px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0 0 4px 0 #cecece;
}

.form-card label {
  display: block;
  font-size: 16px;
  margin: 0 0 6px 0;
}

.v-btn:deep(.v-icon) {
  color: #ffffff;
}

.v-btn:deep(.v-btn__content) {
  color: #ffffff;
}

.v-btn.bg-default:deep(.v-btn__content) {
  color: #000000;
}

.v-textarea:deep(label) {
  line-height: 18px;
}

.v-input:deep(.v-field:hover .v-field__outline__start) {
  border-color: #2f74bf;
}

.v-input:deep(.v-field:hover .v-field__outline__notch::before) {
  border-color: #2f74bf;
}

.v-input:deep(.v-field:hover .v-field__outline__notch::after) {
  border-color: #2f74bf;
}

.v-input:deep(.v-field:hover .v-field__outline__end) {
  border-color: #2f74bf;
}

.v-select--chips:deep(.v-select__selection .v-chip) {
  font-size: 14px;
  color: #ffffff;
  background: #2f74bf;
  text-transform: capitalize;
}

.mt-10-px {
  margin-top: 10px;
}

.v-autocomplete:deep(.v-field .v-chip) {
  font-size: 14px;
  color: #ffffff;
  background: #2f74bf;
}

.v-dialog .v-card:deep(.v-card-title) {
  color: #ffffff;
  background: #2f74bf;
}

.v-dialog .v-card:deep(.v-card-title span) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.v-dialog .v-card:deep(.v-card-title span .v-icon) {
  cursor: pointer;
}

.v-dialog .v-card:deep(.v-card-text) {
  padding: 20px 8px 15px 8px !important;
}

.v-dialog .v-card:deep(.v-card-actions) {
  justify-content: end;
  padding: 10px 20px 15px 20px;
}

.v-dialog .v-card:deep(.v-card-actions .primary-btn) {
  color: #ffffff;
  background: #2f74bf;
}

.v-dialog .v-card:deep(.v-card-actions .primary-btn .v-btn__content) {
  color: #ffffff;
}

.v-dialog .v-card:deep(.v-card-actions .default-btn) {
  color: #2f74bf;
  background: #ffffff;
  border: 1px solid #2f74bf;
}

.default-btn {
  color: #2f74bf;
  background: #ffffff !important;
  border: 1px solid #2f74bf !important;
}

.default-btn:deep(.v-btn__content) {
  color: #2f74bf;
}

.v-dialog .v-card:deep(.v-card-actions .default-btn .v-btn__content) {
  color: #2f74bf;
}

.medicines-table table th {
  background: #efefef !important;
  border-color: #cfcfcf !important;
  padding: 10px 16px 10px 16px !important;
}

.medicines-table table td {
  border-color: #cfcfcf !important;
  padding: 10px 8px 10px 8px !important;
}

.medicines-table table td:first-of-type {
  border-left: 1px solid #cfcfcf;
}

.medicines-table table td:last-of-type {
  border-right: 1px solid #cfcfcf;
}

.medicines-table table th:first-of-type {
  border-radius: 4px 0 0 0;
}

.medicines-table table th:last-of-type {
  border-radius: 0 4px 0 0;
}

.medicines-table table td .v-input:deep(.v-input__details) {
  display: none;
}

.medicines-table:deep(table) {
  margin-bottom: 10px;
  border-radius: 0 0 4px 4px;
  /* border-bottom: 1px solid #cfcfcf; */
}

.medicines-table:deep(.v-data-table-footer) {
  display: none;
}

.h-46 {
  height: 46px !important;
}

.v-expansion-panel {
  border: 1px solid #b1b1b1;
}

.v-expansion-panel:deep(.v-expansion-panel__shadow) {
  display: none;
}

.v-expansion-panel:deep(.v-expansion-panel-text img) {
  width: 100%;
}

.action-row td:last-of-type {
  max-width: 130px;
}

.table-expansion:deep(.v-expansion-panel-text__wrapper) {
  padding: 20px;
}

.table-expansion:deep(.v-expansion-panel-text__wrapper table) {
  margin-bottom: 0;
}

.vitals-expansion:deep(.v-expansion-panel-text__wrapper) {
  margin-top: 15px;
  padding: 20px 24px 15px 24px;
}

/* /// Print Details Modal Styling /// */
.medicine-details {
  padding: 0;
  margin: 0 10px;
  border-radius: 5px;
  border: 1px solid #cecece;
}

.medicine-details .card-list {
  display: flex;
  padding: 10px 10px;
  align-items: center;
  border-bottom: 1px solid #cecece;
}

.medicine-details .card-list:last-of-type {
  border-bottom: 0;
}

.medicine-details .card-list .serial-number {
  width: 26px;
  height: 26px;
  display: flex;
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  padding-top: 1px;
  border-radius: 50%;
  align-items: center;
  background: #2f74bf;
  justify-content: center;
}

.medicine-details .card-list .card-list-item {
  margin-left: 15px;
  padding-right: 15px;
  border-right: 1px solid #cecece;
}

.medicine-details .card-list .card-list-item:last-of-type {
  border-right: 0;
}

.medicine-details .card-list .card-list-item span {
  font-size: 14px;
  color: #000000;
  font-weight: 400;
}

.medicine-details .card-list .card-list-item span:last-of-type {
  font-weight: 500;
}

.images-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.images-wrapper img {
  width: 150px;
  height: 150px;
  cursor: pointer;
  object-fit: cover;
  border-radius: 4px;
}

.delete-image {
  top: 5px;
  z-index: 1;
  right: 6px;
  opacity: 0;
  position: absolute;
  transition: all 0.5s ease;
}
.images-wrapper div:hover .delete-image {
  opacity: 1;
  transition: all 0.5s ease;
}

.image-paragraph {
  font-size: 20px;
  text-align: center;
  margin: 35px 0 30px 0;
}

.danger-btn {
  color: #ffffff;
  background: #ff3333;
}

.close-modal {
  top: 10px;
  right: 10px;
  position: absolute;
}

/* /// Custom Table /// */
.custom-table {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  margin: 0 0 15px 0;
  border-radius: 5px;
  padding: 10px 10px 5px 10px;
  min-width: calc(100vw - 215px);
  border: 1px solid #cecece;
}

.custom-table>div:first-of-type {
  width: 26%;
}

.custom-table>div {
  width: 15%;
}

.custom-table>div:nth-of-type(2) {
  width: 27%;
}

.custom-table>div:nth-of-type(5) {
  width: 17%;
}

.custom-table>div:nth-of-type(6) {
  width: 12%;
}

.custom-table>div:nth-of-type(7) {
  width: 12%;
}

.custom-table>div:nth-of-type(8) {
  width: 12%;
}

.custom-table>div:nth-of-type(9) {
  width: 12%;
}

.custom-table>div:nth-of-type(10) {
  width: 20%;
}

.custom-table>div:nth-of-type(11) {
  width: 25%;
}

.custom-table>div:last-of-type {
  width: 7%;
}

.custom-table>div:nth-of-type(2) td div:first-of-type {
  margin-right: 16px;
}

.custom-table>div td {
  display: flex;
  border-left: 0 !important;
  border-right: 0 !important;
}

.custom-table>div td:deep(.v-autocomplete__selection .v-autocomplete__selection-text) {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-table-btn {
  width: 100%;
  display: flex;
}

.custom-table-btn div td {
  display: flex;
  border-left: 0 !important;
  border-right: 0 !important;
  max-width: 100% !important;
}

.custom-table-btn div:first-of-type {
  width: 55%;
}

.custom-table-btn div:nth-of-type(2) {
  width: 20%;
}

.custom-table-btn div:last-of-type {
  width: 25%;
}
.custom-table-btn div:first-of-type td {
  padding-left: 0 !important;
}
.custom-table-btn div:last-of-type td {
  padding-right: 0 !important;
}
.route-select {
  padding: 10px 8px 10px 8px;
}
.route-select .v-input:deep(.v-input__details) {
  display: none;
}

/* /// Custom Table /// */

@media (min-width: 350px) and (max-width: 767px) {
  .content-wrapper {
    padding: 80px 15px 15px;
  }

  .mobile-view-column {
    flex-direction: column;
    align-items: flex-start;
  }
  .mobile-view-column .v-switch--inset.ml-10 {
    margin-left: 0 !important;
  }

  .form-card {
    padding: 10px 15px;
  }

  .vitals-expansion:deep(.v-expansion-panel-text__wrapper) {
    padding: 15px;
  }

  .table-expansion:deep(.v-expansion-panel-text__wrapper) {
    padding: 10px;
  }

  .custom-table-btn {
    flex-direction: column;
  }

  .custom-table-btn div:first-of-type {
    width: 100%;
  }

  .custom-table-btn div:nth-of-type(2) {
    width: 100%;
  }

  .custom-table-btn div:last-of-type {
    width: 100%;
  }

  .custom-table-btn div td {
    padding: 5px 0 5px 0 !important;
  }

  .custom-table-btn td .v-btn {
    font-size: 12px;
    padding: 2px 3px 0 3px;
  }

  .custom-table {
    flex-direction: column;
  }

  .medicines-table table .custom-table td {
    padding: 6px 10px !important;
  }

  .custom-table>div:first-of-type {
    width: 100%;
    margin-bottom: 5px;
  }

  .custom-table>div:nth-of-type(2) {
    width: 100%;
  }

  .custom-table>div:nth-of-type(2) td div:first-of-type {
    margin-right: 0;
    margin-bottom: 17px;
  }

  .custom-table>div:nth-of-type(2) td {
    display: flex;
    flex-direction: column;
  }

  .custom-table>div {
    width: 100%;
  }

  .custom-table>div:nth-of-type(5) {
    width: 100%;
  }

  .custom-table>div:nth-of-type(6) {
    width: 100%;
  }

  .custom-table>div:nth-of-type(7) {
    width: 100%;
  }

  .custom-table>div:nth-of-type(8) {
    width: 100%;
  }

  .custom-table>div:nth-of-type(9) {
    width: 100%;
  }

  .custom-table>div:nth-of-type(10) {
    width: 100%;
  }

  .custom-table>div:nth-of-type(11) {
    width: 100%;
  }

  .custom-table>div:last-of-type {
    width: 100%;
  }

  .custom-table>div:last-of-type .v-btn {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  .table-expansion:deep(.v-expansion-panel-text__wrapper) {
    padding: 10px;
  }

  .medicines-table table .custom-table-btn td {
    padding: 10px 8px 10px 8px !important;
  }

  .custom-table-btn div:first-of-type {
    width: 50%;
  }

  .custom-table-btn div:nth-of-type(2) {
    width: 18%;
  }

  .custom-table-btn div:last-of-type {
    width: 32%;
  }

  .custom-table-btn div td .v-btn {
    padding: 4px 12px;
  }

  .custom-table>div:first-of-type {
    width: 50%;
  }

  .custom-table>div:nth-of-type(2) {
    width: 50%;
  }

  .custom-table>div:nth-of-type(2) td div:first-of-type {
    margin-right: 10px;
  }

  .custom-table>div {
    width: 35%;
  }

  .custom-table>div {
    width: 35%;
  }

  .custom-table>div:nth-of-type(5) {
    width: 30%;
  }

  .custom-table>div:nth-of-type(6) {
    width: 25%;
  }

  .custom-table>div:nth-of-type(7) {
    width: 25%;
  }

  .custom-table>div:nth-of-type(8) {
    width: 25%;
  }

  .custom-table>div:nth-of-type(9) {
    width: 25%;
  }

  .custom-table>div:nth-of-type(10) {
    width: 40%;
  }

  .custom-table>div:nth-of-type(11) {
    width: 47%;
  }

  .custom-table>div:last-of-type {
    width: 13%;
  }
}

@media (min-width: 901px) and (max-width: 1150px) {
  .table-expansion:deep(.v-expansion-panel-text__wrapper) {
    padding: 15px;
  }

  .custom-table-btn div:first-of-type {
    width: 48%;
  }

  .custom-table-btn div:nth-of-type(2) {
    width: 20%;
  }

  .custom-table-btn div:last-of-type {
    width: 32%;
  }

  .custom-table>div:first-of-type {
    width: 50%;
  }

  .custom-table>div:nth-of-type(2) {
    width: 50%;
  }

  .custom-table>div {
    width: 34%;
  }

  .custom-table>div:nth-of-type(5) {
    width: 32%;
  }

  .custom-table>div:nth-of-type(6) {
    width: 25%;
  }

  .custom-table>div:nth-of-type(7) {
    width: 25%;
  }

  .custom-table>div:nth-of-type(8) {
    width: 25%;
  }

  .custom-table>div:nth-of-type(9) {
    width: 25%;
  }

  .custom-table>div:nth-of-type(10) {
    width: 40%;
  }

  .custom-table>div:nth-of-type(11) {
    width: 49%;
  }

  .custom-table>div:last-of-type {
    width: 11%;
  }
}

@media (min-width: 1151px) and (max-width: 1350px) {
  .medicines-table table .custom-table td {
    padding: 10px 10px 10px 10px !important;
  }

  .custom-table>div:nth-of-type(10) {
    width: 18%;
  }
  .custom-table>div:last-of-type {
    width: 9%;
  }
}

@media (min-width: 350px) and (max-width: 599px) {
  .mobile-view-field {
    flex: 0 0 79%;
    max-width: 79%;
  }

  .mobile-view-btn {
    flex: 0 0 21%;
    max-width: 21%;
  }
}

@media (min-width: 350px) and (max-width: 630px) {
  .mobile-view-precaution {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mobile-view-btn-precaution {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: -15px;
  }
}

@media (min-width: 1030px) and (max-width: 1230px) {
  .medicines-table table .custom-table-btn div td {
    padding: 10px 5px 10px 5px !important;
  }
  .medicines-table table .custom-table-btn div td .v-btn {
    padding: 0 10px;
    font-size: 13px;
  }
}

@media (min-width: 768px) and (max-width: 1029px) {
  .custom-table-btn {
    flex-wrap: wrap;
    justify-content: end;
  }
  .custom-table-btn div:first-of-type {
    width: 48%;
  }
  .custom-table-btn div:nth-of-type(2) {
    width: 26%;
  }
  .custom-table-btn div:nth-of-type(3) {
    width: 26%;
  }
  .custom-table-btn div:nth-of-type(3) td {
    padding-right: 0 !important;
  }
}

</style>
