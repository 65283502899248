import { createWebHashHistory, createRouter } from "vue-router";

// Layouts
import SimpleLayout from "@/layout/SimpleLayout";
import LayoutWithNav from "@/layout/LayoutWithNav";

// Views
import Login from "@/views/Login";
import ForgotPassword from "@/views/ForgotPassword";
import Dashboard from "@/views/Dashboard";
import Settings from "@/views/Settings";
import AddPatient from "@/views/AddPatient";
import PatientVisit from "@/views/PatientVisit";
import PatientVisits from "@/views/PatientVisits";
import AccessDenied from "@/views/AccessDenied";
import PrintPrescription from "@/views/PrintPrescription";
import PrescriptionPrint from "@/views/PrescriptionPrint.vue";
// Config
import { roles } from "@/config";

const { doctor, nurse } = roles;

const routes = [
  {
    path: "",
    alias: "",
    name: "SimpleLayout",
    component: SimpleLayout,
    children: [
      {
        path: "",
        alias: "",
        name: "Login",
        component: Login,
      },
      {
        path: "forgot-password",
        alias: "",
        name: "ForgotPassword",
        component: ForgotPassword,
      },
      {
        path: "access-denied",
        alias: "",
        name: "AccessDenied",
        component: AccessDenied,
      },
      {
        path: "/:linkkey/patient/:patientkey/visit/:visitkey/printprescription",
        alias: "",
        name: "PrintPrescription",
        component: PrintPrescription,
      },
      {
        path: "/:linkkey/patient/:patientkey/visit/:visitkey/prescription-print",
        alias: "",
        name: "PrescriptionPrint",
        component: PrescriptionPrint,
      },
    ],
  },
  {
    path: "/:linkkey",
    alias: "",
    name: "LayoutWithNav",
    component: LayoutWithNav,
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: { permissions: [doctor, nurse] },
      },
      {
        path: "settings",
        name: "Settings",
        component: Settings,
        meta: { permissions: [doctor, nurse] },
      },
      {
        path: "patient/:patientkey",
        name: "AddPatient",
        component: AddPatient,
        meta: { permissions: [doctor] },
      },
      {
        path: "patient/:patientkey/visits",
        name: "PatientVisits",
        component: PatientVisits,
        meta: { permissions: [doctor, nurse] },
      },
      {
        path: "patient/:patientkey/visit/:visitkey",
        name: "PatientVisit",
        component: PatientVisit,
        meta: { permissions: [doctor, nurse] },
      },
      {
        path: "patient/:patientkey/visit/:visitkey/new",
        name: "NewPatientVisit",
        component: PatientVisit,
        meta: { permissions: [doctor, nurse] },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const { permissions } = to.meta;

  if (permissions && permissions.length) {
    // TODO: Get user role from user's profile object, this is temporary.
    const userPermissions = ["doctor"];
    const hasPermission = permissions.some((permission) =>
      userPermissions.includes(permission)
    );
    if (hasPermission) {
      // User has permission, proceed with navigation
      next();
    } else {
      // User doesn't have permission, redirect to access-denied page.
      next("/access-denied");
    }
  } else {
    // No permission on the route, proceed with navigation
    next();
  }
});

export default router;
