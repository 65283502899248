<template>
  <div>
    <div class="title-row mb-4">
      <h2>Diagnoses</h2>
    </div>
    <!-- /// Data Table /// -->
    <div class="table-wrapper">
      <v-data-table :headers="headers" :items="diagnoses" :items-per-page="10" class="elevation-1 fixed-action" item-key="key">
        <template v-slot:[`item.action`]="{ item }">
          <div class="d-flex align-center">
            <v-btn v-if="item.value.visible" class="mr-3" color="primary" @click="toggleVisibility(item.value)">
              <v-icon class="mdi mdi-eye"></v-icon>
            </v-btn>
            <v-btn v-else color="primary" class="mr-3" @click="toggleVisibility(item.value)">
              <v-icon class="mdi mdi-eye-off"></v-icon>
            </v-btn>
            <v-btn color="primary" @click="upsertPreset(item.value)">
              <v-icon class="mdi mdi-pencil"></v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="upsertDialog" class="medicine-modal" width="600">
      <v-card>
        <v-card-title>
          <span class="headline">{{ this.selectedDiagnosis.name ? "Edit" : "Add" }} Medicine Type Preset</span>
          <v-btn
              density="default"
              color="primary"
              size="small"
              icon="mdi-close"
              @click="upsertDialog = false"
          ></v-btn>
        </v-card-title>
        <v-card-text class="pt-9 pb-5">
          <v-row>
            <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
              <v-text-field variant="outlined" color="primary" v-model="selectedDiagnosis.name" label="Diagnosis Name"></v-text-field>
            </v-col>
          </v-row>
          <div class="d-flex align-center justify-end mt-3">
            <v-btn color="primary" text @click="upsertDialog = false">Cancel</v-btn>
            <v-btn color="primary" class="ml-3" text @click="savePreset">Save</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { VDataTable } from "vuetify/labs/VDataTable";
import { db } from "../../db";
import { drugTypes, dosages, medroutes } from "../config";
export default {
  name: "SettingsTabDiagnoses",
  components: {
    VDataTable,
  },
  mounted() {
    this.loadComorbidities();
  },
  methods: {
    loadComorbidities() {
      let linkkey = this.$route.params.linkkey;
      db.appobjects
        .where({ linkkey: linkkey, type: "diagnosis" })
        .toArray()
        .then((diagnoses) => {
          this.diagnoses = diagnoses;
        });
    },
    async savePreset() {
      if (this.selectedDiagnosis.key) {
        this.selectedDiagnosis.modified = Date.now();
        this.selectedDiagnosis.synced = 0;
        await db.appobjects.update(this.selectedDiagnosis.key, JSON.parse(JSON.stringify(this.selectedDiagnosis)));
        this.upsertDialog = false;
        this.loadComorbidities();
      }
      // this.loadDrugs();
      this.selectedDiagnosis = {
        key: "",
        name: "",
      }
    },
    async toggleVisibility(item) {
      item.visible = !item.visible;
      item.synced = 0;
      item.modified = Date.now();
      item = JSON.parse(JSON.stringify(item));
      await db.appobjects.update(item.key, item);
       this.loadComorbidities();
    },
    upsertPreset(preset = null) {
      if (preset) {
        this.selectedDiagnosis = preset;
        this.upsertDialog = true;
      }
    },
  },
  data: () => ({
    diagnoses: [],
    upsertDialog: false,
    selectedDiagnosis: null,
    headers: [
      {
        title: "Diagnosis Name",
        key: "key",
        sortable: true,
        value: "name",
      },
      {
        title: "WHO/ICD Code",
        key: "icdcodes",
        sortable: true,
        value: "icdcodes",
      },
      {
        title: "Action",
        key: "action",
        sortable: false,
        value: "action",
      }
    ]


  }),
  computed: {
    drugTypes() {
      return drugTypes;
    },
    dosages() {
      return dosages;
    },
    medroutes() {
      return medroutes;
    },
  },

}
</script>

<style scoped>

.fixed-action:deep(table th:last-of-type) {
  right: 0;
  position: sticky;
}
.fixed-action:deep(table td:last-of-type) {
  right: 0;
  position: sticky;
}

</style>