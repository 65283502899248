<template>
  <div class="denied-wrapper">
    <img class="access-denied-img" src="./images/denied-access.jpg" alt="" />
    <h2>Access Denied</h2>
    <p>
      You do not have permission to view this page. <br />
      Please check your credentials and try again.
    </p>
  </div>
</template>

<script>
export default {
  name: "AccessDenied"
};
</script>

<style scoped>
.denied-wrapper {
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: whitesmoke;
}

.denied-wrapper p {
  font-size: 24px;
  color: #6c6c6c;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
}

.denied-wrapper h2 {
  font-size: 40px;
  color: #646464;
  font-weight: 700;
  margin: 40px 0 20px 0;
}

.denied-wrapper .access-denied-img {
  width: 200px;
  height: auto;
}
</style>