<template>
  <v-app-bar prominent>
    <v-app-bar-nav-icon class="primary-color" color="primary" v-if="showNavIcon" variant="text" @click="toggleSidebar"></v-app-bar-nav-icon>
    <v-toolbar-title class="flex-box">
      <!-- <img class="logo" src="./images/logo.png" alt="Logo"> -->
    </v-toolbar-title>
    <!--<v-spacer></v-spacer>-->
    <div v-if="showNavIcon" class="mr-3">
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn icon="mdi-dots-vertical" class="primary-color" color="primary" v-bind="props"></v-btn>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-title>
              <v-icon class="mdi mdi-account-circle-outline"></v-icon>
              Profile
            </v-list-item-title>
            <v-list-item-title>
              <v-icon class="mdi mdi-cog-outline"></v-icon>
              Setting
            </v-list-item-title>
            <v-list-item-title @click="logout()">
              <v-icon class="mdi mdi-location-exit"></v-icon>
              Logout
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
const $router = useRouter();

const emitToggleSidebar = defineEmits(["toggle-sidebar"]);

const drawer = ref(false);
const $route = useRoute();

const props = defineProps({
  isDrawer: Boolean,
});

const logout = () => {
  $router.push("/");
};

const showNavIcon = computed(() => $route.path !== "/");

watch(
  () => props.isDrawer,
  (newValue) => {
    drawer.value = newValue;
  }
);

const toggleSidebar = () => {
  drawer.value = !drawer.value;
  emitToggleSidebar("toggle-sidebar", drawer.value);
};
</script>

<style scoped>
.v-list:deep(.v-list-item) {
  padding: 0 !important;
}

.v-list:deep(.v-list-item .v-list-item-title) {
  display: flex;
  margin-bottom: 2px;
  align-items: center;
  padding: 6px 25px 6px 15px;
}

.v-list:deep(.v-list-item .v-list-item-title):hover {
  cursor: pointer;
  color: #ffffff;
  background: #2f74bf;
}

.v-list:deep(.v-list-item .v-list-item-title .v-icon) {
  margin: 0 5px 0 0;
}

.v-toolbar-title:deep(.v-toolbar-title__placeholder) {
  color: #ffffff;
}

.v-btn:deep(.v-btn__content) {
  color: #ffffff;
}

.logo {
  width: 200px;
}
.flex-box:deep(.v-toolbar-title__placeholder) {
  display: flex;
  align-items: center;
}
.primary-color:deep(.v-btn__content) {
  color: #2f74bf;
}

</style>