import {configFormTypes} from "@/config";

const mutations =  {
  UPDATE_PATIENTS: "UPDATE_PATIENTS",
  ADD_PATIENT: "ADD_PATIENT",
  SAVE_DATA: "SAVE_DATA",
  SET_LAST_ACTIVE: "SET_LAST_ACTIVE",
  ADD_CONFIG_DATA: "ADD_CONFIG_DATA",
  ADD_MEDICINE: "ADD_MEDICINE",
};

for(let type in configFormTypes) {
  const addKey = `ADD_${type}`;
  const removeKey = `REMOVE_${type}`;
  mutations[addKey] = addKey;
  mutations[removeKey] = removeKey;
}

export default mutations;
