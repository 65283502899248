<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  mounted() {
    this.$store.dispatch("INIT_UUID");
    let { linkkey } = this.$route.params;
    if (linkkey) {
      this.$store.dispatch("SYNC_DATABASE");
    }
  },
};
</script>

<style scoped>
.v-main {
  padding-top: 0;
  min-height: 100vh;
}

.v-app-bar {
  padding: 0;
}

@media (min-width: 350px) and (max-width: 767px) {
  .v-main {
    padding-left: 0;
  }

}

@media (min-width: 768px) and (max-width: 1150px) {
  .v-main {
    padding-left: 0;
  }

}
</style>
