<template>
  <div>
    <div class="form-card">
      <v-form ref="addPatientForm">
        <v-row gutters="12" class="pt-4">
          <v-col cols="12" md="6" class="pt-0 pb-0">
            <!-- <label>Title</label> -->
            <v-text-field v-model="profile.title" color="primary" label="Title"
              variant="outlined"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pt-0 pb-0">
            <!-- <label>Full Name</label> -->
            <v-text-field v-model="profile.name" color="primary" label="Full Name" variant="outlined"
              :rules="nameRules"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pt-0 pb-0">
            <!-- <label>Qualifications</label> -->
              <v-text-field v-model="profile.qualification" color="primary" label="Qualifications" variant="outlined"></v-text-field>            
          </v-col>
          <v-col cols="12" md="6" class="pt-0 pb-0">
            <!-- <label>Credentials</label> -->
              <v-textarea v-model="profile.credentials" color="primary" label="Credentials" variant="outlined"></v-textarea>            
          </v-col>
          <v-col cols="12" md="6" class="pt-0 pb-0">
            <!-- <label>Credentials</label> -->
            <v-file-input @change="fileChange" label="Clinic Logo"></v-file-input>
          </v-col>
          <v-col v-if="profile.logo" cols="12" md="6" class="pt-0 pb-0">
            <!-- <label>Credentials</label> -->
            <v-img :src="profile.logo" :width="120" ></v-img>
          </v-col>
          <v-col cols="12" md="6" class="pt-0 pb-0">
            <!-- <label>Credentials</label> -->
            <v-select label="Prescription Template" variant="outlined" color="primary" v-model="profile.prescriptiontemplate" :items="['Default', 'Template1', 'LeftTopBlank']"></v-select>
          </v-col>                                        
          <v-col cols="12" md="12" class="d-flex justify-end mb-3">
            <v-btn @click="saveItem" color="primary"> Save </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
        <h2 class="print-view mb-3">Preview</h2>
        <div class="doc-details print-view">
          <h4>{{profile.title}}</h4>
          <h1 class="doc-title">
            {{profile.name}}
            <span>{{profile.qualification}}</span>
          </h1>
          <h4 class="mb-1" v-for="cred of computedCredentials" :key="cred">
            {{cred}}
          </h4>
        </div>
  </div>
</template>

<script>

// import { VDataTable } from "vuetify/labs/VDataTable";
import { db } from "../../db";
import { drugTypes, dosages, medroutes } from "../config";
import { post } from "@/apiClient";
export default {
  name: "SettingsTabPrecautionPresets",
  components: {
  },
  mounted() {
    let linkkey = this.$route.params.linkkey;
    db.appobjects.get(linkkey + "_profile").then((obj) => {
      if (obj) {
        this.profile = obj.profile;
      }
    });
  },
  methods: {

    fileChange(e) {
      let file = e.target.files[0];
      let linkkey = this.$route.params.linkkey;
      let reader = new FileReader();
      reader.onload = (e) => {
        let data = e.target.result;
        this.profile.logo = data;
      };
      reader.readAsDataURL(file);
    },

    async saveItem() {
      let linkkey = this.$route.params.linkkey;
      db.appobjects.put({
        key: linkkey + "_profile",
        linkkey: linkkey,
        type: "drprofile",
        parentid: linkkey,
        profile: JSON.parse(JSON.stringify(this.profile)),
        modified: Date.now(),
        synced: 0
      });
    },

  },
  data: () => ({

    patient: {},
    profile: {
      title: "",
      name: "",
      qualification: "",
      credentials: "",
    }

  }),
  computed: {
    computedCredentials() {
      return this.profile?.credentials?.split("\n");
    },

  },

}
</script>

<style scoped>
.doc-details {
  border: 1px solid #e0e0e0;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
  /* max-width: 400px; */
}
.doc-details h4 {
  color: #585858;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 2px;
}
.doc-details h1 {
  color: #333333;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
}
.doc-details span {
  color: #585858;
  font-size: 12px;
}
.doc-details h4 span {
  color: #000000;
  font-size: 12px;
}
.print-view {
  width: 816px;
  margin: 0 auto;
  max-width: 100%;
}
.doc-title {
  display: inline-block;
}
.doc-title span {
  display: block;
  font-weight: 400;
  text-align: right;
  line-height: 14px;
}
</style>