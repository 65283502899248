<template>
  <div class="content-wrapper">
    <div class="screen-title">
      <h2>My Patients</h2>
      <v-col cols="auto" class="pa-0">
        <v-btn color="primary" class="mr-5" @click="refreshPage">
          <v-icon  class="mdi mdi-refresh "></v-icon>
          
        </v-btn>
        <v-btn color="primary" @click="$router.push('patient/new')">
          <v-icon class="mdi mdi-plus mr-1"></v-icon>
          Add Patient
        </v-btn>
      </v-col>
    </div>
    <div class="table-card">
      <div class="filter-search align-start">
        <!-- <v-select v-model="filterType" color="primary" class="filter-box" label="Patients Filter" :items="filteredOpts"
          variant="outlined"></v-select> -->
        <v-text-field v-model="searchTerm" color="primary" class="search-box" label="Search Patient"
          prepend-inner-icon="mdi-magnify" variant="outlined" @input="savesearchtext"></v-text-field>
        <div class="mobile-view-space">
          <v-btn class="mt-1 ml-3" @click="filterToday" color="primary">Filter Today</v-btn>
          <v-btn class="mt-1 ml-3" @click="clearSearch" color="primary">Clear</v-btn>
        </div>
      </div>
      <v-data-table :headers="tableheaders" :items="patientsList" :items-per-page="30" class="elevation-1 fixed-action" item-key="key">
        <template v-slot:[`item.nextappointment`]="{ item }">
          <span>
            <v-chip :color="item.value.nextappointmentsurgical ? 'red' : 'green' ">
            {{ item.value.nextappointment ? item.value.nextappointment : ''}} <v-icon v-if="item.value.nextappointmentsurgical">mdi-box-cutter</v-icon>
            </v-chip>
          </span>
        </template>        
        <template v-slot:[`item.lastvisit`]="{ item }">
          {{ findLastVisit(item.value) }}
          <span v-if="pvobj[item.value.key]">
            <v-chip v-if="pvobj[item.value.key].lastvisit === item.value.nextappointment" color="green" class="mr-2">
              <v-icon >mdi-check</v-icon>
            </v-chip>
            <v-chip :color="pvobj[item.value.key].lastvisit === item.value.nextappointment ? 'green': ''">
              {{ pvobj[item.value.key].lastvisit }} 
            </v-chip>
          </span>
        </template>
        <template v-slot:[`item.surgical`]="{ item }">
          <!-- {{ findLastVisit(item.value) }} -->
          <span  class="ml-2" v-if="pvobj[item.value.key]">
            <v-chip v-if="pvobj[item.value.key].hassurgical" color="red">
              <v-icon>mdi-box-cutter</v-icon>
            </v-chip>
          </span>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <div class="d-flex align-center">
            <v-btn size="small" color="primary" @click="$router.push('patient/' + item.value.key)">
              <v-icon class="mdi mdi-pencil"></v-icon>
            </v-btn>
            <v-btn size="small" color="primary" @click="scheduleAppintment(item.value)">
              <v-icon class="mdi mdi-calendar"></v-icon>
            </v-btn>            
            <v-btn size="small" color="primary" @click="$router.push('patient/' + item.value.key + '/visits')">
              <v-icon class="mdi mdi-account-clock"></v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="modalPatientAppointment" max-width="850">
      <v-card>
        <v-card-title>
          <span class="headline">Set appointment for {{ modalPatientAppointmentData.name }}</span>
          <!-- <v-btn
              density="default"
              color="primary"
              size="small"
              icon="mdi-close"
              @click="modalPatientAppointment = false"
          ></v-btn> -->
        </v-card-title>
        <v-card-text class="pt-9 pb-5">
          <v-row>
            <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
              <v-text-field v-model="modalPatientAppointmentData.nextappointment" type="date" color="primary" label="Next Appointment"
                variant="outlined" ></v-text-field>              
            </v-col>
            <v-col>
              <v-checkbox class="" v-model="modalPatientAppointmentData.nextappointmentsurgical" label="Surgical Visit" color="primary"></v-checkbox>
            </v-col>
          </v-row>
          <div class="d-flex align-center justify-end mt-3">
            <v-btn color="primary" text @click="modalPatientAppointment = false">Cancel</v-btn>
            <v-btn color="primary" class="ml-3" text @click="saveAppointment">Save</v-btn>
          </div>
        </v-card-text>
      </v-card>

    </v-dialog>
    <span>{{ Math.round(storageUsed) }} MB Used</span>
  </div>
</template>

<script>
import { gTypes } from "@/store";
import { mapGetters } from "vuex";
import { VDataTable } from "vuetify/labs/VDataTable";
import { liveQuery } from "dexie";
import { useObservable } from "@vueuse/rxjs";
import { db } from "../../db";
import { useRoute, useRouter } from "vue-router";
import { SortDirection } from 'aws-amplify';
import { get } from "@/apiClient"
let moment = require("moment");
export default {
  components: {
    VDataTable,
  },
  setup() {
    // const route = useRoute();
    // return {
    //   db,
    //   patients1: useObservable(
    //     liveQuery(() =>
    //       db.appobjects.where({type: "patient", linkkey: route.params.linkkey}).desc().toArray()
    //     )
    //   ),
    // };
  },
  name: "DashboardScreen",
  data: () => ({
    storageUsed: 0,
    modalPatientAppointment: false,
    modalPatientAppointmentData: {},
    patients1: [],
    pvobj: {},
    show: false,
    tableheaders: [
      {
        title: "Serial Number",
        key: "key",
        sortable: true,
      },
      {
        title: "Name",
        key: "name",
      },
      {
        title: "Next Appointment",
        key: "nextappointment",
        sortable: true,
      },
      {
        title: "Last Visit",
        key: "lastvisit",
        sortable: true,
      },      
      {
        title: "Operated",
        key: "surgical",
        sortable: true,
      },      
      // {
      //   title: "Email Address",
      //   key: "email",
      // },
      {
        title: "Phone Number",
        key: "phonenumber",
      },
      // {
      //   title: "CNIC",
      //   key: "cnic",
      // },
      {
        title: "Gender",
        key: "gender",
      },
      {
        title: "Action",
        key: "action",
      },
    ],
    searchTerm: "",
    filterType: null,
    filteredOpts: ["None", "Serial Number", "Email", "Phone Number", "CNIC"],
    filteredOptValues: ["", "key", "name", "email", "cnic", "phonenumber"],
  }),
  computed: {
    ...mapGetters({
      patients: gTypes.GET_PATIENTS,
    }),
    // TODO: Need to refactor this code
    patientsList() {
      const idx = this.filteredOpts.indexOf(this.filterType);
      const filterType = this.filteredOptValues[idx];
      const modifiedSearchTerm = this.searchTerm.toLowerCase();

      let filteredpatients =  this.patients1?.filter((patient) => {
        if (patient[filterType]) {
          return patient[filterType].toLowerCase().includes(modifiedSearchTerm);
        } else {
          return (
            (patient.key &&
              patient.key.toLowerCase().includes(modifiedSearchTerm)) ||
            (patient.name &&
              patient.name.toLowerCase().includes(modifiedSearchTerm)) ||
            (patient.email &&
              patient.email.toLowerCase().includes(modifiedSearchTerm)) ||
            (patient.cnic &&
              patient.cnic.toLowerCase().includes(modifiedSearchTerm)) ||
            (patient.phonenumber &&
              patient.phonenumber.toLowerCase().includes(modifiedSearchTerm)) ||
            (patient.nextappointment &&
              patient.nextappointment.toLowerCase().includes(modifiedSearchTerm))
          );
        }
      });
      if (!filteredpatients) {
        return [];
      }
      // nextappointment is a string date. sort so that todays appointments are on top
      return filteredpatients;
    },
  },
  mounted() {
    // get("gettime").then((res) => {
    //   console.log(res.data);
    // });
    if (!localStorage.getItem("drugs")) {
      this.$store.dispatch("LOAD_DRUGS");
    }
    // var _lsTotal=0,_xLen,_x;for(_x in localStorage){ if(!localStorage.hasOwnProperty(_x)){continue;} _xLen= ((localStorage[_x].length + _x.length)* 2);_lsTotal+=_xLen; console.log(_x.substr(0,50)+" = "+ (_xLen/1024).toFixed(2)+" KB")};
    // console.log("Total = " + (_lsTotal / 1024).toFixed(2) + " KB");
    // navigator.storage.estimate().then((estimate) => {
    //   this.storageUsed = estimate.usage / 1024 / 1024;
    // });
    const route = useRoute();
    document.onmousemove = document.mousedown = document.mouseup = document.onkeydown = document.onkeyup = document.focus = document.touchstart = document.touchend = document.touchmove = document.gesturestart = document.touchcancel = document.scroll = (e) => {
      this.$store.commit("SET_LAST_ACTIVE", Date.now());
    };    
    this.$store.dispatch("SYNC_DATABASE");
    db.appobjects.where({type: "patient", linkkey: route.params.linkkey}).desc().toArray().then((patients) => {
      this.patients1 = patients;
    });
    db.config.get("dashboardsearch").then((search) => {
      if (search) {
        this.searchTerm = search.value;
      }
    });
  },
  methods: {
    refreshPage () {
      //refresh window 
      window.location.reload();

    },
    savesearchtext () {
      db.config.put({key: 'dashboardsearch', value: this.searchTerm});
    },
    clearSearch() {
      this.searchTerm = "";
      this.savesearchtext();
    },
    saveAppointment () {
      db.appobjects.update(this.modalPatientAppointmentData.key, {modified: Date.now(), synced: 0 , nextappointment: this.modalPatientAppointmentData.nextappointment, nextappointmentsurgical: this.modalPatientAppointmentData.nextappointmentsurgical}).then(() => {
        this.modalPatientAppointment = false;
        this.pvobj = {};
        // replace in patients1
        const idx = this.patients1.findIndex((p) => p.key === this.modalPatientAppointmentData.key);
        this.patients1[idx] = this.modalPatientAppointmentData;
      });
    },
    scheduleAppintment (patient) {
      this.modalPatientAppointmentData = JSON.parse(JSON.stringify(patient));
      this.modalPatientAppointment = true;
    },
    findLastVisit (patient) {
      // console.log(patient.key);
      if (this.pvobj[patient.key]) {
        return;
      }
      db.appobjects.where({type: "patientvisit", parentid: patient.key}).desc().toArray().then((visits) => {
        if (visits.length > 0) {
          let lv = new Date(visits[0].visitdate);
          lv = moment(lv).format("YYYY-MM-DD");
          this.pvobj[patient.key] = {
            lastvisit: lv,
            hassurgical: visits.filter((visit) => visit.visittype === "Surgical Visit").length > 0,
          };
        }
      });
      return;
    },
    filterToday ( ) {
      // get todays date in yyyy-mm-dd format using moment
      const today = moment().format("YYYY-MM-DD");
      this.searchTerm = today;
      this.savesearchtext();
    }
    
  },
};
</script>

<style scoped>
.content-wrapper {
  padding: 84px 24px 24px;
}

.screen-title {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
}

.screen-title h2 {
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  letter-spacing: 0.5px;
}

.table-card {
  padding: 10px 20px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0 0 4px 0 #cecece;
}

.filter-search {
  display: flex;
  margin-top: 10px;
  align-items: center;
}

.filter-search .filter-box {
  max-width: 220px;
}

.filter-search .filter-box:deep(.v-label) {
  line-height: 16px;
}

.filter-search .filter-box:deep(.v-field__input) {
  min-height: 45px;
  padding: 10px 0 10px 15px;
}

.filter-search .filter-box:deep(.v-field__append-inner) {
  padding-top: 12px;
}

.filter-search .filter-box:deep(.v-field__outline__end) {
  border-radius: 0;
}

.filter-search .search-box {
  flex: 1;
}

.filter-search .search-box:deep(.v-field__input) {
  min-height: 45px;
  padding: 10px 10px 10px 6px;
}

.filter-search .search-box:deep(.v-label) {
  line-height: 16px;
}

.filter-search .search-box:deep(.v-field__prepend-inner) {
  padding-top: 12px;
}

.filter-search .search-box:deep(.v-field__outline__start) {
  border-radius: 0;
}

.v-btn:deep(.v-icon) {
  color: #ffffff;
}

.v-btn:deep(.v-btn__content) {
  color: #ffffff;
}

td .v-btn:deep(.v-icon) {
  font-size: 18px;
}

.v-btn.bg-default:deep(.v-btn__content) {
  color: #000000;
}

.v-input:deep(.v-field:hover .v-field__outline__start) {
  border-color: #2f74bf;
}

.v-input:deep(.v-field:hover .v-field__outline__notch::before) {
  border-color: #2f74bf;
}

.v-input:deep(.v-field:hover .v-field__outline__notch::after) {
  border-color: #2f74bf;
}

.v-input:deep(.v-field:hover .v-field__outline__end) {
  border-color: #2f74bf;
}

td .v-btn {
  padding: 0;
  min-width: 35px;
  margin-right: 5px;
}

.v-table:deep(.v-data-table-footer) {
  padding-bottom: 12px;
}
.fixed-action:deep(table th:last-of-type) {
  right: 0;
  position: sticky;
}
.fixed-action:deep(table td:last-of-type) {
  right: 0;
  position: sticky;
}

@media (min-width: 350px) and (max-width: 650px) {
  .table-card {
    padding: 10px 10px;
    white-space: nowrap;
  }

  .content-wrapper {
    padding: 80px 15px 15px;
  }

  .filter-search {
    flex-direction: column;
  }

  .filter-search .filter-box {
    width: 100%;
    max-width: 100%;
  }

  .filter-search .search-box {
    flex: 1;
    width: 100%;
  }

  .filter-search .filter-box:deep(.v-field__outline__end) {
    border-radius: 0 4px 4px 0;
  }

  .filter-search .search-box:deep(.v-field__outline__start) {
    border-radius: 4px 0 0 4px;
  }

  .table-card .v-table:deep(table th) {
    font-size: 14px;
  }

  .table-card .v-table:deep(table td) {
    font-size: 14px;
  }

  .table-card .v-table:deep(.v-data-table-footer) {
    padding-left: 0;
    padding-right: 0;
    padding-top: 15px;
    padding-bottom: 12px;
  }

  .table-card .v-table:deep(.v-data-table-footer .v-data-table-footer__items-per-page) {
    width: 100%;
    padding: 0 15px 5px 15px;
  }

  .table-card .v-table:deep(.v-data-table-footer .v-data-table-footer__items-per-page span) {
    flex: 1;
  }

  .table-card .v-table:deep(.v-data-table-footer .v-data-table-footer__items-per-page .v-input) {
    max-width: 100px;
  }

  .table-card .v-table:deep(.v-data-table-footer .v-data-table-footer__info) {
    padding: 0 6px;
  }
  .mobile-view-space {
    margin-bottom: 15px;
  }
  .mobile-view-space .v-btn:first-of-type {
    margin-left: 0 !important;
  }

}

@media (min-width: 651px) and (max-width: 1120px) {
  .table-card {
    padding: 10px 10px;
    white-space: nowrap;
  }

  .table-card .v-table:deep(table th) {
    font-size: 14px;
  }

  .table-card .v-table:deep(table td) {
    font-size: 14px;
  }
}
</style>
