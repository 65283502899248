<template>
  <div class="mobile-view">
    <v-navigation-drawer v-model="drawer" :rail="!rail" permanent style="border: 0;
    box-shadow: 0 0 5px 0 #cecece;">
      <v-list class="nav-title" density="compact" nav style="min-height: calc(100vh - 64px);">
        <v-list-item color="primary" :prepend-icon="item.icon" :value="item.title" v-for="item in items" :key="item.title"
          :title="item.title" @click="handleClick(item)">
        </v-list-item>
        <v-btn variant="text" icon="mdi-arrow-right-bold" color="primary" class="nav-toggle"
          @click.stop="rail = !rail"></v-btn>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script setup>
import { ref, watch, defineEmits, defineProps } from "vue";
import { useRouter } from "vue-router";

const emitToggleSidebar = defineEmits(["toggle-sidebar"]); // Define emitted event

// const localDrawer = ref(false);
const $router = useRouter();

const props = defineProps({
  drawer: Boolean,
});

const drawer = ref(true);
const rail = ref(false);

const items = [
  {
    title: "Dashboard",
    icon: "mdi-view-dashboard",
    route: { name: "Dashboard", params: { linkkey: $router.currentRoute.value.params.linkkey } },
  },
  {
    title: "Settings",
    icon: "mdi-cog-outline",
    route: { name: "Settings", params: { linkkey: $router.currentRoute.value.params.linkkey } },
  },
  {
    title: "Clinics",
    icon: "mdi-hospital-building",
    route: { name: "Login", params: { linkkey: $router.currentRoute.value.params.linkkey } },
  },
];

watch(
  () => props.drawer,
  (newValue) => (rail.value = newValue)
);

watch(rail, (newValue) => {
  if (!newValue) {
    emitToggleSidebar("toggle-sidebar", rail.value);
  }
});

const handleClick = (item) => $router.push(item.route);
</script>

<style scoped>
.nav-toggle {
  left: 8px;
  bottom: 8px;
  position: absolute;
  width: 40px !important;
  height: 40px !important;
}

.v-navigation-drawer--rail .nav-toggle {
  transform: rotate(0deg) !important;
}

.v-navigation-drawer--left .nav-toggle {
  transform: rotate(180deg);
}

.nav-title .v-list-item:deep(.v-list-item__content .v-list-item-title) {
  font-size: 16px;
  font-weight: 400;
  padding-top: 2px;
}

.nav-title .v-list-item:deep(.v-list-item__prepend .v-icon) {
  font-size: 24px;
  margin-right: 15px;
}

@media (min-width: 350px) and (max-width: 767px) {
  .mobile-view:deep(.v-navigation-drawer--rail) {
    width: 0 !important;
  }

  .mobile-view:deep(.v-navigation-drawer--left) {
    box-shadow: rgb(206, 206, 206) 0 0 20px 0 !important;
  }
}

@media (min-width: 768px) and (max-width: 1150px) {
  .mobile-view:deep(.v-navigation-drawer--rail) {
    width: 0 !important;
  }

  .mobile-view:deep(.v-navigation-drawer--left) {
    box-shadow: rgb(206, 206, 206) 0 0 20px 0 !important;
  }
}
</style>
