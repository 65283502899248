import Dexie from 'dexie';
export const db = new Dexie('objectdb');
db.version(1).stores({
  appobjects: '++key, linkkey, parentid, type, modified, synced', // Primary key and indexed props
  config: '++key',
  images: '++key, imagedata, modified, synced',
  drugs: '++key, linkkey, parentid, name, generics, modified, synced, medtype',
});

db.version(2).stores({
  appobjects: '++key, linkkey, parentid, type, modified, synced, [linkkey+type], [parentid+type]'
});

db.version(3).stores({
  drugs: '++key',
  images: '++key, modified, synced',
  appobjects: '++key, linkkey, parentid, synced, [linkkey+type], [parentid+type]'
});



db.version(4).stores({
  appobjects: '++key, linkkey, parentid, synced, type, [linkkey+type], [parentid+type]'
});

