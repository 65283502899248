<template>
  <div style="max-width: 816px">
    <div>Date: {{ formatVisitDate(visitSummary?.visitdate) }}</div>
    <div>Name: {{ patient?.name }}</div>
    <div>Age: {{ ageFromDate(patient?.dateofbirth) }}</div>
    <div class="mb-5 mt-5">
        <h4>Diagnosis:</h4> 
        <div class="ml-10" v-for="diag of diagnosis" :key="diag">
            {{diag}}
        </div>
    </div>


    <div>
        <h4>
                Prescription:
        </h4>

    </div>
    <div v-for="(drug) in prescMeds" :key="drug.key">
        <span class="text-body-2">{{ drug.drug }}</span>
        <div class="ml-10 text-body-2">
            {{drugStatementEnglish(drug)}}
        </div>
        <div class="ml-10 font-weight-bold text-subtitle-2" style="direction: rtl">
            {{drugStatementUrdu(drug)}}
        </div>
    </div>
    <div class="printdiv">
        <v-btn color="primary" @click="print">Print</v-btn>
        <v-btn color="primary" class="ml-5" @click="closeWindow">Close window</v-btn>
    </div>
    <!-- {{medicine}}
        {{comorbidity}} -->
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { liveQuery } from "dexie";
import { useObservable } from "@vueuse/rxjs";
import { db } from "../../db";
import moment from "moment";
import { medroutesUrdu, dosagesUrdu, durationsUrdu, timingsUrdu } from "@/config"
export default {
  name: "PrintPrescription",
  mounted() {
    this.loadPrescMeds();
  },
  data: () => ({
    prescMeds: [],
  }),
  methods: {
    drugStatementUrdu (pd) {
        let uduration = durationsUrdu.find(d=>d.english==pd.durationtype);
        let urdu = ``;
        let urdudosage = dosagesUrdu.find(d=>d.english==pd.dosage);
        
        if (urdudosage) {
            urdudosage = urdudosage.urdu;
        } else {
            urdudosage = pd.dosage
        }
        if (pd.morning) {
            urdu += ` ${pd.morning} ${urdudosage?urdudosage:""} ${timingsUrdu.morning}, `;
        }
        if (pd.afternoon) {
            urdu += `${pd.afternoon} ${urdudosage?urdudosage:""} ${timingsUrdu.afternoon}, `;
        }
        if (pd.evening) {
            urdu += `${pd.evening} ${urdudosage?urdudosage:""} ${timingsUrdu.evening}, `;
        }
        if (pd.night) {
            urdu += `${pd.night} ${urdudosage?urdudosage:""} ${timingsUrdu.night}, `;
        }
        urdu = urdu.substring(0, urdu.length - 2);
        urdu += ` - ${pd.duration?pd.duration:""} ${uduration ? uduration.urdu : pd.durationtype} `
        return urdu;
    },
    drugStatementEnglish ( pd ) {
        console.log("pd", pd);
        let english = ``
        if (pd.morning) {
            english += `${pd.morning} ${pd.dosage?pd.dosage:""} Morning, `;
        }
        if (pd.afternoon) {
            english += `${pd.afternoon} ${pd.dosage?pd.dosage:""} Afternoon, `;
        }
        if (pd.evening) {
            english += `${pd.evening} ${pd.dosage?pd.dosage:""} Evening, `;
        }
        if (pd.night) {
            english += `${pd.night} ${pd.dosage?pd.dosage:""} Night, `;
        }
        //remove last comma
        english = english.substring(0, english.length - 2);
        english += ` - ${pd.duration?pd.duration:""} ${ pd.durationtype} `;
        return english;
    },
    async loadPrescMeds() {
      const route = useRoute();
      const { visitkey: parentid, patientkey } = route.params;
      db.appobjects
        .get({ parentid, type: "visit_medicines" })
        .then(async (data) => {
          if (!data) return;
          let meddetail = JSON.parse(data.detail);
          for (let i = 0; i < meddetail.length; i++) {
            await db.drugs.get(meddetail[i].medid).then((drug) => {
              meddetail[i].drug = drug.name;
            });
          }
          this.prescMeds = meddetail;
          console.log("meds", data);
        });
    },
    formatVisitDate(date) {
      return moment(date).format("Do MMMM YYYY, h:mm a");
    },
    closeWindow() {
      window.close();
    },
    ageFromDate(date) {
      if (!date) return "";
      return moment().diff(date, "years");
    },
    print() {
      window.print();
      //window.onfocus = function () { setTimeout(function () { window.close(); }, 500); }
      // window.close();
    },
  },
  computed: {
    diagnosis() {
        if (!this.visitData) return [];
        if (!this.diagnoses) return [];
        let vd = this.visitData.find((x) => x.type === "visit_diagnosis")
        if (!vd) return [];
        let vdd = JSON.parse(vd.detail)
        for (let i = 0; i < vdd.length; i++) {
            vdd[i] = this.diagnoses.find((x) => x.key === vdd[i]).name;
        }
        return vdd;
    },

  },
  setup() {
    const route = useRoute();
    const { visitkey: parentid, patientkey } = route.params;
    return {
      visitData: useObservable(
        liveQuery(() => db.appobjects.where({ parentid }).toArray())
      ),
      visitSummary: useObservable(liveQuery(() => db.appobjects.get(parentid))),
      patient: useObservable(liveQuery(() => db.appobjects.get(patientkey))),
      comorbidity: useObservable(
        liveQuery(() => db.appobjects.where({ type: "comorbidity" }).toArray())
      ),
      diagnoses: useObservable(
        liveQuery(() => db.appobjects.where({ type: "diagnosis" }).toArray())
      ),
    };
  },
};
</script>

<style>

.printdiv {
    margin-left: 20px;
    margin-top: 20px;
}

@media print {
    .printdiv {
        display: none;
    }
}
</style>