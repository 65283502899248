<template>
    <div class="print-wrapper">
        <div class="print-content">
            <div class="detail-container">
                <div class="doc-details">
                    <h4></h4>
                    <h1></h1>
                    <div class="text-right line-height">
                        <span></span>
                    </div>
                    <h4 class="mb-1"></h4>
                </div>
            </div>
            <!--/// Doctor & Patient Details End Here ///-->
            <div class="treatment-details">
                <div class="complaints-investigation">
                    <div class="investigation-card">
                        <h2></h2>
                        <div class="text-body-2"></div>
                    </div>
                </div>
                <!--///-->
                <div class="treatment-advice">
                    <div class="d-flex">
                        <div class="w-32 pr-3">
                            <div class="investigation-card ml-0">
                                <h2>Presenting Complaints</h2>
                                <div class="text-body-2">
                                    {{ visit_history }}
                                </div>
                            </div>
                            <div class="investigation-card ml-0">                             
                                <h2>Past History</h2>
                                <div class="text-body-2">{{ visit_pasthistory }}</div>
                            </div>
                       
                        </div>
                        <div class="w-32 pr-3">
                            <div class="investigation-card ml-0">
                                <h2>Examination</h2>
                                <div class="text-body-2">
                                    {{ visit_examination }}
                                </div>
                            </div>                             
                            <div class="investigation-card ml-0">
                                <h2>Comorbidities</h2>
                                <div class="text-body-2">
                                    {{ visit_comorbidities }}
                                </div>
                            </div>
                        </div>
                        <div class="w-36">
                            <div class="investigation-card ml-0 min-h-90">
                                <h2>Patient Information</h2>
                                <div class="patient-details">
                                    <div class="user-detail">
                                        Date:
                                        <span>{{ formatVisitDate(visitSummary?.visitdate) }}</span>
                                    </div>
                                    <div class="user-detail">
                                        Patient Name:
                                        <span>{{ patient?.name }}</span>
                                    </div>
                                    <div class="user-detail">
                                        Patient Age:
                                        <span>{{ ageFromDate(patient?.dateofbirth) }}</span>
                                    </div>
                                    <div class="user-detail">
                                        Patient Number:
                                        <span>{{ patient?.key }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="investigation-card ml-0">
                                <h2>Investigations</h2>
                                <div class="text-body-2">
                                    {{ visit_investigations }}
                                </div>
                                <h2>Futher Investigations</h2>
                                <div class="text-body-2">
                                {{ visit_furtherinvestigations }}
                                </div>
                                <div class="text-body-2">
                                {{ visit_furtherinvestigationslist }}
                                </div>                                
                                
                            </div>
                           
                        </div>
                    </div>
                    <!--//-->
                    <div class="treatment-card">
                        <div class="card mnh-200">
                            <div v-if="Array.isArray(diagnosis)" class="bottom-border">
                            <h2>Diagnosis</h2>
                                <div class="text-body-1">
                                    <strong>{{ Array.isArray(diagnosis) ? diagnosis.join(", ") : diagnosis }}</strong>
                                </div>
                            </div>
                            <div v-if="visit_treatment" class="bottom-border">                            
                            <h2>Treatment</h2>
                                <div class="text-body-1">
                                    <strong>{{ visit_treatment }}</strong>
                                </div>
                            </div>
                            <h2>Medication</h2>
                            <div class="" v-for="(drug) in prescMeds" :key="drug.key">
                                <h3>{{ drug.drug }} {{ drug.note ? `(${drug.note})`: '' }}</h3>
                                <div class="auto-alignment">
                                    <h4>{{ drugStatementEnglish(drug) }}</h4>
                                    <p style="direction: rtl">
                                        {{ drugStatementUrdu(drug) }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--//-->
                    <div class="treatment-card">
                        <div class="card min-height-80 mt-0 pt-0" v-if="Array.isArray(visit_precautions)">
                            <h2>Precautions ( احتیاطی تدابیر)</h2>
                            <div class="auto-alignment" v-for="(prec) in visit_precautions" :key="prec.key">
                                <h4>{{ prec?.name }}</h4>
                                <p style="direction: rtl">
                                    {{ prec?.urdu }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
  
<script>
import moment from "moment";
import { medroutesUrdu, dosagesUrdu, durationsUrdu, timingsUrdu, visitFields } from "@/config"
export default {
    name: "PrescriptionPrintView",
    props: {
        visitSummary: {
            type: Object,
            required: false,
        },
        patient: {
            type: Object,
            required: false,
        },
        drprofile: {
            type: Object,
            required: false,
        },
        diagnosis: {
            type: Array,
            required: false,
        },
        prescMeds: {
            type: Array,
            required: false,
        },
        visit_history: {
            type: String,
            required: false,
        },
        visit_pasthistory: {
            type: String,
            required: false,
        },
        visit_examination: {
            type: String,
            required: false,
        },
        visit_investigations: {
            type: String,
            required: false,
        },
        visit_furtherinvestigationslist: {
            type: String,
            required: false,
        },
        visit_furtherinvestigations: {
            type: String,
            required: false,
        },
        visit_nextvisit: {
            type: Object,
            required: false,
        },
        visit_treatment: {
            type: String,
            required: false,
        },
        visit_precautions: {
            type: Array,
            required: false,
        },
        visit_comorbidities: {
            type: String,
            required: false,
        },
        computedCredentials: {
            type: Object,
            required: false,
        },
        medfrequencies: {
            type: Array,
            required: false,
        },

    },
    methods: {
        formatVisitDate(date) {
            return moment(date).format("Do MMMM YYYY, h:mm a");
        },
        ageFromDate(date) {
            if (!date) return "";
            return moment().diff(date, "years");
        },
        drugStatementEnglish(pd) {
            let english = ``
            if (pd.morning) {
                english += `${pd.morning} ${pd.dosage ? pd.dosage : ""} Morning, `;
            }
            if (pd.afternoon) {
                english += `${pd.afternoon} ${pd.dosage ? pd.dosage : ""} Afternoon, `;
            }
            if (pd.evening) {
                english += `${pd.evening} ${pd.dosage ? pd.dosage : ""} Evening, `;
            }
            if (pd.night) {
                english += `${pd.night} ${pd.dosage ? pd.dosage : ""} Night, `;
            }
            //remove last comma
            english = english.substring(0, english.length - 2);
            english += ` - ${pd.medfrequency ? this.medfrequencies?.find(x => x.key === pd.medfrequency)?.name : ""}  `;
            english += ` - ${pd.duration ? pd.duration : ""} ${pd.durationtype} `;
            return english;
        },
        drugStatementUrdu(pd) {
            let uduration = durationsUrdu.find(d => d.english == pd.durationtype);
            let urdu = `   `;
            let urdudosage = dosagesUrdu.find(d => d.english == pd.dosage);

            if (urdudosage) {
                urdudosage = urdudosage.urdu;
            } else {
                urdudosage = pd.dosage
            }
            if (pd.morning) {
                urdu += ` ${pd.morning} ${urdudosage ? urdudosage : ""} ${timingsUrdu.morning}, `;
            }
            if (pd.afternoon) {
                urdu += `${pd.afternoon} ${urdudosage ? urdudosage : ""} ${timingsUrdu.afternoon}, `;
            }
            if (pd.evening) {
                urdu += `${pd.evening} ${urdudosage ? urdudosage : ""} ${timingsUrdu.evening}, `;
            }
            if (pd.night) {
                urdu += `${pd.night} ${urdudosage ? urdudosage : ""} ${timingsUrdu.night}, `;
            }
            urdu = urdu.substring(0, urdu.length - 2);
            urdu += ` - ${pd.medfrequency ? this.medfrequencies?.find(x => x.key === pd.medfrequency).urdu : ""}  `;
            urdu += ` - ${pd.duration ? pd.duration : ""} ${uduration ? uduration.urdu : pd.durationtype} `
            return urdu;
        },
    },
    data: () => ({

    }),
};
</script>
  
<style scoped>
.bottom-border {
    border-bottom: 1px solid black;
}
.print-wrapper {
    display: flex;
    padding: 15px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.print-content {
    width: 816px;
    max-width: 816px;
    padding: 10px 15px;
    border-radius: 4px;
    border: 1px solid #cecece;
}

.line-height {
    line-height: 14px;
}

.detail-container {
    display: flex;
    min-height: 115px;
    padding-bottom: 5px;
    justify-content: space-between;
    /* border-bottom: 1px solid #e9e9e9; */
}

.patient-details {
    min-width: auto;
    max-width: inherit;
    border: #000000 solid 1px;
    padding: 5px;
}
.patient-details .user-detail {
  margin: 8px 0 8px 0;
}
.patient-details .user-detail:last-of-type {
  margin: 8px 0 0 0;
}
.w-32 {
  width: 32% !important;
}
.w-36 {
  width: 36% !important;
}

.logo {
    width: 120px;
}

.doc-details h4 {
    color: #585858;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 2px;
}

.doc-details h1 {
    color: #333333;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
}

.doc-details span {
    color: #585858;
    font-size: 12px;
}

.doc-details h4 span {
    color: #000000;
    font-size: 12px;
}

.user-detail {
    display: flex;
    /* color: #585858; */
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    margin: 6px 10px 6px 0px;
    justify-content: space-between;
}

.user-detail span {
    font-weight: 500;
}

.treatment-details {
    display: flex;
    padding-top: 10px;
    position: relative;
}

.complaints-investigation {
    min-width: 225px;
    /* max-width: 100px; */
    padding-right: 10px;
}

.complaints-investigation .investigation-card {
    width: 100%;
    padding: 10px;
    min-height: 65px;
    margin-bottom: 5px;
    /* border: 1px solid #cecece; */
}

.complaints-investigation .investigation-card h2 {
    color: #000000;
    font-size: 12px;
    font-weight: 500;
}

.treatment-advice {
    flex: 1;
    padding-left: 10px;
}

.treatment-advice .investigation-card {
    width: 100%;
    padding: 5px;
    min-height: 70px;
    /* margin-bottom: 10px; */
    /* border: 1px solid #cecece; */
}

.treatment-advice .investigation-card:last-of-type {
    margin-left: 20px;
}

.treatment-advice .investigation-card h2 {
    color: #000000;
    font-size: 12px;
    font-weight: 500;
}

.treatment-card h2 {
    color: #000000;
    font-size: 14px;
    font-weight: 500;
}

.treatment-card .card {
    width: 100%;
    padding: 10px;
    min-height: auto;
    margin-bottom: 10px;
    /* border: 1px solid #cecece; */
}

.treatment-card .card h4 {
    color: #333333;
    font-size: 12px;
    font-weight: 400;
}

.treatment-card .card p {
    font-size: 12px;
    font-weight: 600;
    text-align: right;
    font-family: monospace;
}

.treatment-card .card h3 {
    color: #000000;
    font-size: 12px;
    font-weight: 600;
}

.min-height-80 {
    min-height: 70px !important;
}

.treatment-advice .investigation-card.mnh-40 {
    min-height: 40px;
    padding: 5px 8px;
    margin-bottom: 5px;
}

.treatment-card .card.mnh-200 {
    min-height: 200px;
    margin: 0px;
}

.treatment-card .card.mnh-300 {
    min-height: 300px;
}


.complaints-investigation .investigation-card.mnh-200 {
    min-height: 200px;
}

.complaints-investigation .investigation-card.mnh-300 {
    min-height: 300px;
}

.complaints-investigation .investigation-card.mnh-252 {
    min-height: 252px;
}

.auto-alignment {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.auto-alignment p {
  margin: 0 0 0 auto;
}

@media print {
    .print-content {
        padding: 0;
        border: none;
    }

    .print-wrapper {
        padding: 0;
    }

    .treatment-advice .investigation-card {
        border: none;
    }

    .treatment-card .card {
        border: none;
    }

    .complaints-investigation .investigation-card {
        border: none;
    }

    .print-wrapper {
        align-items: flex-start;
        /* flex-direction: column; */
    }

}
</style>
<script setup>
</script>