<template>
  <div>
    <div class="title-row d-flex align-center justify-space-between mb-4">
      <h2>Medicine Type Presets</h2>
      <div>
        <v-btn color="primary" class="ml-4" @click="upsertPreset(null)">
          <v-icon class="mdi mdi-plus mr-1"></v-icon>
          Add Medicine Type Preset
        </v-btn>
        <v-btn color="primary" class="ml-4" @click="exportItems">Export</v-btn>
        <v-btn color="primary" class="ml-4" @click="importItems">Import</v-btn>
      </div>
    </div>
    <!-- /// Data Table /// -->
    <div class="table-wrapper">
      <v-data-table :headers="headers" :items="medTypePresets" :items-per-page="10" class="elevation-1 fixed-action" item-key="key">
        <template v-slot:[`item.medtypes`]="{ item }">
          {{ item.value.medtypes.join(", ") }}
        </template>
        <template v-slot:[`item.dosage`]="{ item }">
          {{ item.value.defaults.dosage }}
        </template>
        <template v-slot:[`item.morning`]="{ item }">
          {{ item.value.defaults.morning }}
        </template>
        <template v-slot:[`item.afternoon`]="{ item }">
          {{ item.value.defaults.afternoon }}
        </template>
        <template v-slot:[`item.evening`]="{ item }">
          {{ item.value.defaults.evening }}
        </template>
        <template v-slot:[`item.night`]="{ item }">
          {{ item.value.defaults.night }}
        </template>
        <template v-slot:[`item.route`]="{ item }">
          {{ item.value.defaults.route }}
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <div class="d-flex align-center">
            <v-btn color="primary" @click="upsertPreset(item.value)">
              <v-icon class="mdi mdi-pencil"></v-icon>
            </v-btn>
            <v-btn color="primary" class="ml-3" @click="deletePreset(item.value)">
              <v-icon class="mdi mdi-delete"></v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </div>
            

  <v-dialog v-model="upsertDialog" class="medicine-modal" width="800">
    <v-card>
      <v-card-title>
        <span class="headline">{{ this.selectedPreset.name ? "Edit" : "Add" }} Medicine Type Preset</span>
        <v-btn
            density="default"
            color="primary"
            size="small"
            icon="mdi-close"
            @click="upsertDialog = false"
        ></v-btn>
      </v-card-title>

      <v-card-text class="pt-8 pb-5">
        <v-row>
          <v-col cols="12" sm="6" md="6" class="pt-1 pb-1">
            <v-text-field color="primary" variant="outlined" v-model="selectedPreset.name" label="Preset Name"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="pt-1 pb-1">
            <v-select color="primary" variant="outlined" label="Drug Types" v-model="selectedPreset.medtypes" multiple chips :items="drugTypes">
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="pt-1 pb-1">
            <v-select color="primary" variant="outlined" label="Dosage" v-model="selectedPreset.defaults.dosage" :items="dosages">
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="pt-1 pb-1">
            <v-select color="primary" variant="outlined" item-value="key" item-title="name" label="Frequency" v-model="selectedPreset.defaults.medfrequency" :items="medfrequencies">
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="pt-1 pb-1">
            <v-text-field color="primary" variant="outlined" v-model="selectedPreset.defaults.morning" label="Morning"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="pt-1 pb-1">
            <v-text-field color="primary" variant="outlined" v-model="selectedPreset.defaults.afternoon" label="Afternoon"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="pt-1 pb-1">
            <v-text-field color="primary" variant="outlined" v-model="selectedPreset.defaults.evening" label="Evening"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="pt-1 pb-1">
            <v-text-field color="primary" variant="outlined" v-model="selectedPreset.defaults.night" label="Night"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="pt-1 pb-1">
            <v-select color="primary" variant="outlined" label="Route" v-model="selectedPreset.defaults.route" :items="medroutes">
            </v-select>
          </v-col>
        </v-row>
        <div class="d-flex align-center justify-end mt-3">
          <v-btn color="primary" text @click="upsertDialog = false">Cancel</v-btn>
          <v-btn color="primary" class="ml-3" text @click="savePreset">Save</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
  <v-dialog v-model="importDialog" class="medicine-modal" width="800">
    <v-card>
      <v-card-title>
        <span class="headline">Import Presets</span>
        <v-btn
            density="default"
            color="primary"
            size="small"
            icon="mdi-close"
            @click="importDialog = false"
        ></v-btn>
      </v-card-title>

      <v-card-text class="pt-8 pb-5">
        <v-row>
          <v-col cols="12" sm="6" md="6" class="pt-1 pb-1">
            <v-file-input
              v-model="file"
              accept="application/json"
              label="Select a JSON file"
              outlined
            ></v-file-input>
          </v-col>
        </v-row>
        <div class="d-flex align-center justify-end mt-3">
          <v-btn color="primary" text @click="importDialog = false">Cancel</v-btn>
          <v-btn color="primary" class="ml-3" text @click="importPresetItems">Save</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
import { capitalize } from 'vue';
/* Example preset object

    {
        key: "default1",
        name: "TabCap 1x1x1",
        medtype: "tabcap",
        defaults: {
            dosage: "tablet(s)",
            morning: 1,
            afternoon: 1,
            evening: 0,
            night: 1,
            route: "Oral",
        }
    }

  */

import { VDataTable } from "vuetify/labs/VDataTable";
import { db } from "../../db";
import { drugTypes, dosages, medroutes } from "../config";
export default {
  name: "SettingsTabMedTypePresets",
  components: {
    VDataTable,
  },
  mounted() {
    // this.loadDrugs();
    this.loadPresets();
  },
  methods: {
    loadPresets() {
      let linkkey = this.$route.params.linkkey;
      db.appobjects.where("type").equals("medtypepreset").and((preset) => {
        return preset.linkkey == linkkey;
      }).toArray().then((presets) => {
        this.medTypePresets = presets;
      });

      // load medfrequencies
      db.appobjects.where("type").equals("medfrequency").and((medfrequency) => {
        return medfrequency.linkkey == linkkey;
      }).toArray().then((medfrequencies) => {
        console.log("medfrequencies", medfrequencies);
        this.medfrequencies = medfrequencies;
      });

    },
    async savePreset() {
      this.selectedPreset.modified = Date.now();
      this.selectedPreset.synced = 0;
      if (!this.selectedPreset.key) {
        this.selectedPreset.key = "medtypepreset_" + Date.now();
        this.selectedPreset.linkkey = this.$route.params.linkkey;
        this.selectedPreset.type = "medtypepreset";
        this.selectedPreset.parentid = this.$route.params.linkkey;
        this.selectedPreset.visible = true;
        await db.appobjects.add(JSON.parse(JSON.stringify(this.selectedPreset)));
      } else {
        await db.appobjects.update(this.selectedPreset.key, JSON.parse(JSON.stringify(this.selectedPreset)));
      }
      // this.loadDrugs();
      this.upsertDialog = false;
      this.selectedPreset = {
        key: "",
        name: "",
        medtypes: [],
        
        defaults: {
          medfrequency: "",
          dosage: "",
          morning: 0,
          afternoon: 0,
          evening: 0,
          night: 0,
          route: "",
        }
      }
      this.loadPresets();
    },
    importItems () {
      //for upload
      this.importDialog = true;
    },
    async exportItems () {
      //for download
      const data = {medtypepresets : this.medTypePresets}

      //get all medfrequency
      let linkkey = this.$route.params.linkkey;
      let medfrequencies = await db.appobjects.where({linkkey: linkkey, type: "medfrequency"}).toArray();
      data.medfrequencies = medfrequencies;
      
      const blob = new Blob([JSON.stringify(data)], { type: 'application/json' })
      

      const href = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = href
      a.download = 'medtypepresets.json'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    async deletePreset(preset) {
      preset.visible = false;
      preset.modified = Date.now();
      preset.synced = 0;
      preset.type = "deletedmedtypepreset";
      preset = JSON.parse(JSON.stringify(preset));
      await db.appobjects.update(preset.key, preset);
      this.loadPresets();
    },
    async importPresetItems () {
      //for upload
      const reader = new FileReader()
      reader.onload = (e) => {
        const data = JSON.parse(e.target.result)
        console.log(data);
        // update keys for medfrequency
        for (let i = 0; i < data.medfrequencies.length; i++) {
          // find all medtypepresets where medfrequency is used
          let newkey = "medfrequency_" + Date.now() + i;
          let medtypepresets = data.medtypepresets.filter((preset) => {
            return preset.defaults.medfrequency == data.medfrequencies[i].key;
          });
          // console.log(medtypepresets);
          for (let j = 0; j < medtypepresets.length; j++) {
            medtypepresets[j].defaults.medfrequency = newkey;
            medtypepresets[j].key = "medtypepreset_" + Date.now() + j;
            medtypepresets[j].linkkey = this.$route.params.linkkey;
            medtypepresets[j].parentid = this.$route.params.linkkey;
            medtypepresets[j].modified = Date.now();
            medtypepresets[j].synced = 0;
            medtypepresets[j].type = "medtypepreset";
            medtypepresets[j] = JSON.parse(JSON.stringify(medtypepresets[j]));
            db.appobjects.add(medtypepresets[j]);

          }
          const medfrequency = data.medfrequencies[i];
          medfrequency.key = newkey;
          medfrequency.linkkey = this.$route.params.linkkey;
          medfrequency.parentid = this.$route.params.linkkey;
          medfrequency.visible = true;
          medfrequency.synced = 0;
          medfrequency.modified = Date.now();
          db.appobjects.add(medfrequency);
        }
        this.loadPresets();
      }
      console.log(this.file);
      reader.readAsText(this.file[0])
      this.importDialog = false;

    },
    upsertPreset(preset = null) {
      if (preset) {
        this.selectedPreset = preset;
      } else {
        this.selectedPreset = {
          key: "",
          name: "",
          medtypes: [],
          medfrequency: "",
          defaults: {
            dosage: "",
            morning: 0,
            afternoon: 0,
            evening: 0,
            night: 0,
            route: "",
          }
        };
      }
        this.upsertDialog = true;
    },
  },
  data: () => ({
    medTypePresets: [],
    medfrequencies: [],
    upsertDialog: false,
    importDialog: false,
    selectedPreset: null,
    file: null,
    headers: [
      {
        title: "Preser Name",
        key: "key",
        sortable: true,
        value: "name",
      },
      {
        title: "Medicine Types",
        key: "medtypes",
        sortable: true,
        value: "medtypes",
      },
      {
        title: "Dosage",
        key: "dosage",
        sortable: false,
        value: "dosage",
      },
      {
        title: "Morning",
        key: "morning",
        sortable: false,
        value: "morning",
      },
      {
        title: "Afternoon",
        key: "afternoon",
        sortable: false,
        value: "afternoon",
      },
      {
        title: "Evening",
        key: "evening",
        sortable: false,
        value: "evening",
      },
      {
        title: "Night",
        sortable: false,
        key: "night",
        value: "night",
      },
      {
        title: "Route",
        key: "route",
        sortable: false,
        value: "route",
      },
      {
        title: "Action",
        key: "action",
        sortable: false,
        value: "action",
      }
    ]


  }),
  computed: {
    drugTypes() {
      return drugTypes;
    },
    dosages() {
      return dosages;
    },
    medroutes() {
      return medroutes;
    },
  },

}
</script>

<style scoped>

.fixed-action:deep(table th:last-of-type) {
  right: 0;
  position: sticky;
}
.fixed-action:deep(table td:last-of-type) {
  right: 0;
  position: sticky;
}

</style>