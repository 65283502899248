<template>
  <div class="login-container">
    <div class="card">
      <!-- <img class="logo" src="./images/edu-logo.png" alt="" /> -->
      <authenticator>
        <template v-slot="{ signOut }">
          <h1>Welcome to OneClinic</h1>
          <div>
            <!-- <v-btn @click="gotoClinic(clinic)" v-for="clinic in clinics" :key="clinic.key">
              {{ clinic.clinicname }}
            </v-btn> -->
            <router-link
              v-for="clinic in clinics"
              :key="clinic.key"
              :to="'/' + clinic.linkkey + '/dashboard'"
              >{{ clinic.clinicname }}</router-link
            >
          </div>
          <v-btn color="primary" @click="newClinicDialog = true" v-if="allownc">Create New Clinic</v-btn>
          <v-btn color="primary" @click="signOut">Sign Out</v-btn>
        </template>
      </authenticator>
    </div>
    <v-dialog v-model="newClinicDialog" width="400" >
      <v-card>
        <v-card-title>
          <span class="headline">Create New Clinic</span>
          <v-btn
            density="default"
            color="primary"
            size="small"
            icon="mdi-close"
            @click="newClinicDialog = false"
          ></v-btn>
        </v-card-title>
        <v-card-text class="pt-9 pb-5">
          <v-row>
            <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
              <v-text-field variant="outlined" color="primary" v-model="newClinic.doctorname" label="Doctor Name"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
              <v-text-field variant="outlined" color="primary" disabled v-model="computedclinicname" label="Clinic Name"></v-text-field>
            </v-col>
          </v-row>
          <div class="d-flex align-center justify-end mt-3">
            <v-btn color="primary" text @click="newClinicDialog = false">Cancel</v-btn>
            <v-btn color="primary" class="ml-3" text @click="saveNewClinic">Create</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { Authenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";
import { Auth, Hub } from "aws-amplify";
import { db } from "../../db";
import { post } from "@/apiClient"
const $router = useRouter();

// Amplify.configure(awsconfig);

// console.log(Auth);
// $router.push("/clinic1/dashboard");
</script>
<script>
// import { defineComponent } from '@vue/composition-api'
import axios from "axios";
export default {
  setup() {
    // const user = ref(null)
    // const signOut = () => {
    //   user.value = null
    // }
    // console.log(user);
    // return {
    //   user,
    //   signOut,
    // }
  },

  methods: {
    gotoClinic(clinic) {
      console.log(clinic);
      // console.log("gotoClinic");
      // this.$router.push("/clinic1/dashboard");
    },
    saveNewClinic() {
      Auth.currentSession()
        .then((res) => {
          console.log(res);
          axios
            .post(
              process.env.VUE_APP_BASE_URL + "createnewclinic",
              {
                doctorname: this.newClinic.doctorname,
                clinicname: this.cleanClinicname(this.newClinic.doctorname),
              },
              {
                headers: {
                  Authorization: res.idToken.jwtToken,
                },
              }
            )
            .then((res1) => {
              console.log(res1);
              this.newClinicDialog = false;
              this.getClinics();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          // console.timeEnd("Auth.currentSession()");
        });
    },
    cleanClinicname(doctorname) {
      return doctorname.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
    },
    getClinics() {
      Auth.currentSession()
        .then((res) => {
          console.log(res);
          db.config.get(res.idToken.payload["email"] + "_clinics").then((res1) => {
            if (res1) {
              this.clinics = res1.clinics;
            }
          }
          );
          localStorage.setItem("token", res.idToken.jwtToken);
          axios
            .get(process.env.VUE_APP_BASE_URL + "clinics", {
              headers: {
                Authorization: res.idToken.jwtToken,
              },
            })
            .then((res1) => {
              this.clinics = res1.data.clinics;
              if (this.clinics.length == 0) {
                this.allownc = true;
              } else {
                this.allownc = false;
              }
              db.config.put({
                key: res.idToken.payload["email"] + "_clinics",
                clinics: res1.data.clinics,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          // console.timeEnd("Auth.currentSession()");
        });
    },
  },
  computed: {
    computedclinicname() {
      return this.cleanClinicname(this.newClinic.doctorname);
    },
  },
  mounted() {
    this.$store.dispatch("STOP_SYNC");

    Hub.listen("auth", (data) => {
      const { payload } = data;
      switch (payload.event) {
        case "signIn":
          // console.log("user signed in");
          this.getClinics();
          break;
        case "signOut":
          console.log("user signed out");
          break;
        case "signIn_failure":
          console.log("user sign in failed");
          break;
        default:
          console.log("default");
          break;
      }
    });
    this.getClinics();
  },
  data: () => ({
    clinics: [],
    allownc: false,
    newClinicDialog: false,
    newClinic: {
      clinicname: "",
      doctorname: "",
    },
  }),
};
</script>



<style scoped>
.login-container {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}

.login-container .card {
  width: 500px;
  border-radius: 4px;
  background: #ffffff;
  padding: 15px 15px 10px;
  box-shadow: 0 4px 12px 0 #cecece;
}

.login-container .card .logo {
  width: 250px;
  height: auto;
  display: block;
  margin: 15px auto 35px;
}

.v-input:deep(.v-field .v-field__overlay) {
  background-color: inherit;
}

.login-container .card:deep(.v-label) {
  margin: 0;
  line-height: 16px;
}

.ml-minus-10 {
  margin-left: -10px;
}

.primary-color {
  color: #2f74bf;
}

.invalid-credentials-msg {
  font-size: 16px;
  margin-top: 15px;
  color: #000000;
  text-align: center;
  border-radius: 5px;
  padding: 10px 15px;
  background: #ff00001c;
}

.v-btn:deep(.v-btn__content) {
  color: #ffffff;
}
.login-container
  .card:deep(div[data-amplify-authenticator] div[data-amplify-container]) {
  width: 100% !important;
}
.login-container
  .card:deep(
    div[data-amplify-authenticator]
      div[data-amplify-container]
      div[data-amplify-router]
  ) {
  border: 0;
  background: transparent;
  box-shadow: 0 0 0 0 #cecece;
}
.login-container .card:deep(.amplify-tabs-item[data-state="active"]) {
  color: #ffffff;
  background: #2f74bf;
}
.login-container .card:deep([data-amplify-authenticator] [data-amplify-form]) {
  padding: 15px 10px 10px 10px;
}
.login-container
  .card:deep(.amplify-field-group :not(:first-child) .amplify-input) {
  border-radius: 4px;
}
.login-container
  .card:deep([data-amplify-authenticator] [data-amplify-footer]) {
  padding-bottom: 0;
}
.login-container .card:deep(.amplify-textfield) {
  gap: 0;
}
.login-container .card:deep(.amplify-field-group__field-wrapper input) {
  font-size: 14px;
  padding: 9px 15px 8px 15px;
}
.login-container
  .card:deep(.amplify-field-group__field-wrapper input::placeholder) {
  color: #a7a7a7;
}
.login-container .card:deep(.amplify-textfield label) {
  font-size: 14px;
  font-weight: 500;
}
.login-container .card:deep(h1) {
  text-align: center;
}
.login-container .card:deep(div a) {
  color: #ffffff;
  display: block;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  border-radius: 5px;
  background: #2f74bf;
  margin: 15px 0 15px 0;
  text-decoration: none;
  padding: 9px 15px 7px 15px;
}
.login-container .card:deep(button[data-v-26084dc2]) {
  width: 100%;
  color: #ffffff;
  display: block;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  border-radius: 5px;
  margin: 0 0 15px 0;
  background: #2f74bf;
  text-decoration: none;
  padding: 9px 15px 7px 15px;
}

@media (min-width: 350px) and (max-width: 550px) {
  .login-container .card {
    width: 90%;
  }

  .login-container .card .logo {
    width: 250px;
    margin: 20px auto 30px;
  }
}
</style>
