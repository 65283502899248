<template>
  <div>
    <div class="title-row">
      <div class="d-flex align-center justify-space-between mb-4 mbl-wrap">
        <h2>Medicines</h2>
        <div class="">
          <v-btn class="mr-4" @click="loadDrugsFromWS" color="primary">Load Drugs</v-btn>
          <v-btn color="primary" @click="upsertDrug(null)">
            <v-icon class="mdi mdi-plus mr-1"></v-icon>
            Add Medicine
          </v-btn>
        </div>
      </div>
      <v-text-field label="Search" color="primary" variant="outlined" v-model="searchtxt"></v-text-field>
    </div>
    <!-- /// Data Table /// -->
    <div class="table-wrapper">
        <v-data-table :headers="headers" :items="filteredDrugs" :items-per-page="10" class="elevation-1" item-key="key">
          <!-- <template v-slot:[`item.visitdate`]="{ item }">
            {{ formatVisitDate(item.value.visitdate) }}
          </template> -->
          <template v-slot:[`item.action`]="{ item }">
            <v-btn color="primary" @click="upsertDrug(item.value)">
              <v-icon class="mdi mdi-pencil"></v-icon>
            </v-btn>
          </template>
        </v-data-table>
    </div>
    <v-dialog v-model="upsertDialog" class="medicine-modal" width="600">
      <v-card>
        <v-card-title>
          <span class="headline">{{ this.selectedDrug.name ? "Edit" : "Add" }} Medicine</span>
          <v-btn
              density="default"
              color="primary"
              size="small"
              icon="mdi-close"
              @click="upsertDialog = false"
          ></v-btn>
        </v-card-title>
        <v-card-text class="pt-9 pb-5">
          <v-row>
            <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
              <v-text-field variant="outlined" color="primary" v-model="selectedDrug.name" label="Drug Name"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
              <v-text-field variant="outlined" color="primary" v-model="selectedDrug.generics" label="Generic Name"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
              <v-select variant="outlined" color="primary" label="Drug Type" v-model="selectedDrug.medtype" :items="drugTypes"></v-select>
            </v-col>
          </v-row>
          <div class="d-flex align-center justify-end mt-3">
            <v-btn color="primary" text @click="upsertDialog = false">Cancel</v-btn>
            <v-btn color="primary" class="ml-3" text @click="saveDrug">Save</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* drug types are:
infusion: 49,
  tablet: 8826,
  cream: 289,
  syrup: 576,
  solution: 68,
  gel: 103,
  capsule: 2435,
  sachet: 49,
  spray: 28,
  lotion: 62,
  drops: 467,
  injection: 3213,
  ointment: 151,
  suspension: 2061,
  inhaler: 29,
  unknown: 301,
  liquid: 61,
  powder: 81,
  syringe: 5,
  patch: 2,
  evohaler: 5,
  suppository: 3,
  granules: 2,
  toothpaste: 6,
  vial: 5,
  lozenges: 5
  */

import { VDataTable } from "vuetify/labs/VDataTable";
import { db } from "../../db";
import {drugTypes, drugs} from "../config";
export default {
  name: "SettingsTabMedicines",
  components: {
    VDataTable,
  },
  mounted() {
    this.loadDrugs();
  },
  methods: {
    loadDrugsFromWS() {
      this.$store.dispatch("LOAD_DRUGS");
    },    
    async loadDrugs() {
      let dbdrugs = drugs
      let linkkey = this.$route.params.linkkey;
      let clinicdrugs = await db.appobjects.where({linkkey: linkkey, type: "medicine"}).toArray();
      // concat the two arrays
      dbdrugs = dbdrugs.concat(clinicdrugs);
      // console.log(clinicdrugs);
      this.drugs = dbdrugs;
    },
    async saveDrug() {
      if (!this.selectedDrug.key) {
        let linkkey = this.$route.params.linkkey;
        let drugid = this.selectedDrug.name + this.selectedDrug.generics;
        drugid = drugid.replace(/[^a-zA-Z0-9]/g, "");        
        this.selectedDrug.linkkey = linkkey;
        this.selectedDrug.type = "medicine";
        this.selectedDrug.parentid = linkkey;
        this.selectedDrug.key = drugid + Math.floor(Math.random() * 1000);
      }
      this.selectedDrug.synced = 0;
      this.selectedDrug.modified = Date.now();
      await db.appobjects.put(JSON.parse(JSON.stringify(this.selectedDrug)));
      this.loadDrugs();
      this.upsertDialog = false;
      this.selectedDrug = {
        name: "",
        generics: "",
        medtype: "",
      };
    },
    upsertDrug(drug = null) {
      console.log(drug);
      this.selectedDrug = drug;
      if (!this.selectedDrug) {
        this.selectedDrug = {
          name: "",
          generics: "",
          medtype: "",
        };
      }
        this.upsertDialog = true;
    },
  },
  data: () => ({
    drugs: [],
    searchtxt: "",
    upsertDialog: false,
    selectedDrug: null,
    headers: [
      {
        title: "Drug Name",
        key: "key",
        sortable: true,
        value: "name",
      },
      {
        title: "Generic Name",
        sortable: true,
        value: "generics",
      },
      {
        title: "Drug Type",
        sortable: true,
        value: "type",
      },
      {
        title: "Action",
        sortable: false,
        value: "action",
        key: "action",
      }
    ]


  }),
  computed: {
    drugTypes() {
      return drugTypes;
    },
    filteredDrugs() {
      if (this.searchtxt.length < 3) {
        return this.drugs;
      }
      return this.drugs.filter((drug) => {
        //search text can have multiple words
        //split it into array of words
        //check if all words are present in drug name or generics
        //if yes then return true
        //if no then return false
        
        let searchWords = this.searchtxt.split(" ");
        let drugName = drug.name.toLowerCase();
        let drugGenerics = drug.generics?.toLowerCase() || "";
        let drugType = drug.type?.toLowerCase() || "";
        let isDrugNameMatched = true;
        let isDrugGenericsMatched = true;
        let isDrugTypeMatched = true;
        searchWords.forEach((word) => {
          if (!drugName.includes(word.toLowerCase())) {
            isDrugNameMatched = false;
          }
          if (!drugGenerics.includes(word.toLowerCase())) {
            isDrugGenericsMatched = false;
          }
          if (!drugType.includes(word.toLowerCase())) {
            isDrugTypeMatched = false;
          }
        });
        return isDrugNameMatched || isDrugGenericsMatched || isDrugTypeMatched;

        // return drug.name.toLowerCase().includes(this.searchtxt.toLowerCase()) || drug.generics.toLowerCase().includes(this.searchtxt.toLowerCase());
      });
    },
  },

}
</script>

<style>
.medicine-modal .v-card-title {
  display: flex;
  font-size: 20px;
  color: #ffffff;
  font-weight: 500;
  padding: 10px 24px;
  align-items: center;
  background: #2f74bf;
  justify-content: space-between;
}
.medicine-modal .v-card-title .v-btn {
  box-shadow: 0 0 0 0 #cecece;
}

.table-wrapper td .v-btn {
  min-width: 36px;
  min-height: 30px;
  max-height: 30px;
  padding: 0 4px 0;
}

@media (min-width: 350px) and (max-width: 599px) {
  .table-wrapper {
    white-space: nowrap;
  }
  .v-data-table-footer {
    padding: 0;
  }
  .v-data-table-footer .v-data-table-footer__items-per-page {
    width: 100%;
    padding: 10px 0 5px 0;
  }
  .v-data-table-footer .v-data-table-footer__items-per-page span {
    flex: 1;
  }
  .v-data-table-footer .v-data-table-footer__items-per-page .v-input {
    max-width: 100px;
  }
  .v-data-table-footer .v-data-table-footer__info {
    padding-inline-end: 0;
  }
  .table-wrapper th:last-of-type {
    right: 0;
    position: sticky;
  }
  .table-wrapper td:last-of-type {
    right: 0;
    position: sticky;
  }
  .title-row {
    flex-wrap: wrap;
  }
  .title-row h2 {
    margin-bottom: 12px;
  }
}

@media (min-width: 600px) and (max-width: 767px) {
  .table-wrapper th:last-of-type {
    right: 0;
    position: sticky;
  }
  .table-wrapper td:last-of-type {
    right: 0;
    position: sticky;
  }
}

</style>